import React, { useState } from 'react';
import {
    IonItem,
    IonIcon,
    IonLabel,
    IonText,
    IonButton,
    IonChip
} from "@ionic/react";

import { chevronDownOutline, document, list, downloadOutline } from 'ionicons/icons';

import './AccordionItem.scss';
import { useUrl } from '../context/Url';

type Props = {
    id: number;
    children?: any;
    type?: string;
    createdAt?: string;
    fileId?: number;
    related?: any;
    title?: string;
}

const DocumentAccordionItem: React.FC<Props> = props => {
    const { children, type, createdAt, fileId, title, id } = props;
    const { fileUrl }= useUrl();
    const [ isOpen, setIsOpen ] = useState(false);
    var dateFormat = require('dateformat');

    const handleOpen = () => { 
        setIsOpen(!isOpen);
    }

    const getColor = () => {
        switch (type) {
            case 'App\\Models\\DocumentCustomerContract':
                return 'success';
            case 'App\\Models\\DocumentInvoice':
                return 'warning';
            case 'App\\Models\\DocumentPatrolControl':
                return 'danger';
            case 'App\\Models\\DocumentProtocol':
                return 'secondary';
            default:
                return 'primary';
        }
    }

    return (
        <div className="accordionItemWrapper">
            <IonItem className={"accordionItem " + (isOpen ? "isOpen" : "")} lines="none" button={true} detail={false} onClick={() => handleOpen()}>
                <IonIcon icon={document} slot="start" color={getColor()} size="small" />
                <IonLabel>
                    <IonText color="secondary">
                        <h2 className="title">{title??''}</h2>
                    </IonText>
                    {createdAt &&
                        <IonChip color={getColor()}>
                            <IonLabel>{createdAt ? dateFormat(createdAt, 'yyyy.mm.dd HH:MM') : ''}</IonLabel>
                        </IonChip>
                    }
                </IonLabel>
                <IonIcon icon={chevronDownOutline} slot="end" size="small" className="chevronIcon" />
            </IonItem>
            <div className={"subItem " + (isOpen ? "isOpen" : "")}>
                <div className="subItem-inner">
                    {/*<div className="list-between">
                        <span>File név:</span>
                        <span>{fileName??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Méret:</span>
                        <span>{fileSize??' - '}</span>
                    </div>*/}
                    <div className="list-between">
                        <span>Feltöltve:</span>
                        <span>{createdAt ? dateFormat(createdAt, 'yyyy.mm.dd HH:MM') : ''}</span>
                    </div>

                    {children??''}

                    <br/>
                    <div className="rightButton">
                        <IonButton size="small" routerLink={"/my/documents/view/" + id} routerDirection="forward" color="primary">
                            <IonIcon icon={list} slot="start" />
                            Részletek
                        </IonButton>
                        {fileId &&
                            <IonButton size="small" href={fileUrl + "/" + fileId} target="_blank" color="success">
                                <IonIcon icon={downloadOutline} slot="start" />
                                PDF megnyitása
                            </IonButton>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentAccordionItem;

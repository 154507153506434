import React, { useEffect, useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonAlert, IonLoading } from '@ionic/react';

import useFormValidation from '../../helpers/FormValidationHelper';
import ValidateSms from '../../helpers/validators/ValidateSms';
import { Redirect } from 'react-router';
import { useAuth } from '../../context/Auth';
import Axios from 'axios';
import { setLoading, setInitError, hasError, addApiErrors, ErrorsList } from '../../helpers/GlobalHelpers';

import { Capacitor } from '@capacitor/core';
import { SmsRetriever } from '@ionic-native/sms-retriever';
import { useUrl } from '../../context/Url';

const INIT_STATE = {
    smskod: '',
};

interface Props {
    setCurrentPage: (num: number) => void;
    relation: any,
}

const AssignSmsPage: React.FC<Props> = ({ setCurrentPage, relation }) => {
    const { authToken, setAssigns, checkAxiosError } = useAuth();
    const { apiUrl } = useUrl();
    const { handleChange, values, handleDirectChange } = useFormValidation(INIT_STATE);
    const [status, setStatus] = useState({
        loading: false,
        error_list: INIT_STATE,
    });

    const [showSmsAlert, setShowSmsAlert] = useState(false);
    const [showSmsSuccess, setShowSmsSuccess] = useState(false);
    const [showSmsRedirect, setShowSmsRedirect] = useState(false);
    const [showSmsResend, setShowSmsResend] = useState(false);
    const [showSmsError, setShowSmsError] = useState<string|null>(null);

    const smsRetriever = SmsRetriever;

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === 'Enter') {
            handleSendSmsKod();
        }
    }

    const handleSendSmsKod = async () => {
        setLoading(true, setStatus);
        setInitError(INIT_STATE, setStatus);

        const errors = ValidateSms(values);
        if (hasError(errors)) {
            setLoading(false, setStatus);
            addApiErrors(errors, setStatus);
        } else {
            Axios.post(`${apiUrl}/v1/assign/sms-verification`, {
                relation_id: relation.id,
                sms_code: values.smskod,
            },
            {
                headers: {'Authorization': `Bearer ${authToken}`},
            }).then(res => {
                setLoading(false, setStatus);
                setAssigns(relation);
                setShowSmsSuccess(true);
            }).catch(err => {
                checkAxiosError(err);
                setLoading(false, setStatus);
                
                if(!err.response) {
                    addApiErrors('Hálózati hiba.', setStatus);
                } else {
                    addApiErrors(err.response.data.message, setStatus);
                }

                setShowSmsAlert(true);
            });
        }
    }

    const handleNewSmsKod = async (loader: boolean = true) => {
        if(loader) {
            setLoading(true, setStatus);
        }

        Axios.post(`${apiUrl}/v1/assign/sms-send-code`, {
            relation_id: relation.id,
        },
        {
            headers: {'Authorization': `Bearer ${authToken}`},
        }).then(res => {
            if(loader) {
                setLoading(false, setStatus);

                if(!res.data.error) {
                    setShowSmsResend(true);
                } else {
                    setShowSmsError(res.data.message);
                }
            }
        }).catch(err => {
            checkAxiosError(err);
            if(loader) {
                setLoading(false, setStatus);
                setShowSmsError(err.response.data.message);
            }
        });
    }

    const startSmsListener = () => {
        if (Capacitor.isNative) {
            smsRetriever.startWatching()
            .then((res: any) => {
                processSMS(res.Message);
            })
            .catch((error: any) => {
                console.error(error);
            });
        }
    }

    const processSMS = (message: string) => {
        if (message && message.indexOf('myPatent') !== -1) {
            const regex = /\d{6}/g;
            var kod = message.match(regex);

            if(kod.length > 0) {
                handleDirectChange('smskod', kod[0]);
            }
        }
    }

    const setHiddenMobile = (str: string) => {
        var arr = str.split('');
        let i = 0;
        for (let index = arr.length; index >= 0; index--) {
            if(i >= 2 && i <= 4) {
                arr[index] = "*";
            }
            i++;
            if(i > 5) {
                break;
            }
        }
        return arr.join('');
    }

    useEffect(() => {
        startSmsListener();
        handleNewSmsKod(false);
    }, [])

    return (
        <>
            <h4>A megadott azonosító helyes!</h4>
            
            <p>
                Az Ön azonosítóját rendszerünk megtalálta.
                <br/>
                Az alábbi mobil telefonszámra hamarosan egy megerősítő kódot fogunk kiküldeni:<br/>
                <b className="smsText">{setHiddenMobile(relation.verify_phone_number)}</b>
                <br/><br/>
                Kérjük írja be a kapott kódot:
            </p>

            <div className="form">
                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.smskod ? 'error' : '')}>
                    <IonLabel position="floating">Kapott SMS kód</IonLabel>
                    <IonInput onKeyDown={handleKeyDown} clearOnEdit={false} type="text" inputmode="text" name="smskod" onIonChange={handleChange} value={values.smskod} required autofocus={true}></IonInput>
                </IonItem>
            </div>

            <ErrorsList errors={status.error_list} />

            <IonButton color="success" expand="block" onClick={handleSendSmsKod}>Kód beküldése</IonButton>
            <IonButton color="medium" expand="block" onClick={() => handleNewSmsKod(true)}>Új SMS-t kérek</IonButton>

            <p>
                Ez nem az Ön száma, vagy nem kapja meg a kódot? Kérjük adja meg telefonszámát amelyen kollégáink felkereshetik Önt jóváhagyás céljából.
            </p>

            <IonButton color="medium" expand="block" onClick={() => setCurrentPage(1)}>Telefonszám megadása</IonButton>

            <IonAlert
                isOpen={showSmsAlert}
                onDidDismiss={() => setShowSmsAlert(false)}
                header={'Hiba'}
                message={'A beírt megerősítő kód nem megfelelő! Próbálja újra, vagy kérjen új megerősítő kódot vagy már 3 megerősítő SMS került kiküldésre, többre nincs lehetőség. Kérjük vegye fel velünk a kapcsolatot a jóváhagyáshoz!'}
                buttons={[
                    {
                        text: 'Vissza',
                        role: 'cancel',
                    }
                ]}
            />

            <IonAlert
                isOpen={showSmsSuccess}
                onDidDismiss={() => setShowSmsRedirect(true)}
                header={'Siker'}
                message={'Profilját sikeresen hozzárendelte MyPatent fiókjához.'}
                buttons={[
                    {
                        text: 'Irány a főoldal',
                        handler: () => setShowSmsRedirect(true),
                    }
                ]}
            />

            <IonAlert
                isOpen={showSmsResend}
                onDidDismiss={() => setShowSmsResend(false)}
                header={'Siker'}
                message={'Új kód kiküldve SMS-ben!'}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
            />

            {showSmsError !== null &&
                <IonAlert
                    isOpen={(showSmsError !== null)}
                    onDidDismiss={() => setShowSmsError(null)}
                    header={'Hiba'}
                    message={showSmsError}
                    buttons={[
                        {
                            text: 'Rendben',
                            role: 'cancel',
                        }
                    ]}
                />
            }

            {showSmsRedirect &&
                <Redirect to="/my/dashboard" />
            }

            <IonLoading
                isOpen={status.loading}
                message={'Kérem várjon...'}
            />
        </>
    );
};

export default AssignSmsPage;

import React, { useState, useEffect } from 'react';
import PageWrapper from '../layouts/PageWrapper';

import { Redirect, useParams } from 'react-router';
import Axios from 'axios';
import { useAuth } from '../../context/Auth';
import { IonAlert, IonButton, IonCol, IonIcon, IonLoading, IonRow, IonText } from '@ionic/react';
import NumberFormat from 'react-number-format';
import { cardOutline, checkmarkCircleOutline, documentTextOutline } from 'ionicons/icons';
import { useUrl } from '../../context/Url';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { Capacitor } from '@capacitor/core';

interface Documents {
    id: number;
    type: string;
    createdAt: string;
    related: any;
    transactions?: any;
    fileId?: number|null;
    invoice_data?: any;
    invoice_items_data?: any;
}

const InvoicesPaymentPage: React.FC = () => {
    const { authToken, checkAxiosError } = useAuth();
    const { apiUrl, fileUrl } = useUrl();
    const { id } = useParams<any>();
    const [ invoice, setInvoice ] = useState<Documents>(null);
    var dateFormat = require('dateformat');

    useEffect(() => {
        Axios.post(`${apiUrl}/v1/invoices/show`,
        {
            id: id,
        },
        {
            headers: { 'Authorization': `Bearer ${authToken}` },
        }).then(res => {
            if (res.data.data) {
                setInvoice({
                    id: res.data.data.id,
                    type: res.data.data.related_type,
                    createdAt: res.data.data.created_at,
                    related: res.data.data.related,
                    transactions: res.data.data.transactions,
                    fileId: res.data.data.file_id,
                    invoice_data: res.data.data.invoice_data,
                    invoice_items_data: res.data.data.invoice_items_data,
                });
            }
        }).catch(err => {
            checkAxiosError(err);
        });
    }, []);

    /*const getColor = () => {
        switch (getStatus()) {
            case 'waiting':
                return 'warning';
            case 'paid':
                return 'success';
            case 'late':
                return 'danger';
            default:
                return 'primary';
        }
    }*/

    const getStatus = () => {
        if(invoice.related.U_CPH_PaymentCreated !== undefined && invoice.related.U_CPH_PaymentCreated !== null && invoice.related.U_CPH_PaymentCreated === 'Y') {
            return 'paid';
        }

        if(invoice.transactions !== undefined && invoice.transactions.length > 0 && invoice.transactions[0] !== undefined && invoice.transactions[0].status === 'FINISHED') {
            return 'paid';
        } else {
            var d1 = new Date();
            var d2 = new Date(invoice.related.DocDueDate);
            if (d1 > d2) {
                return 'late';
            } else {
                return 'waiting';
            }
        }
    }

    const getStatusText = () => {
        switch (getStatus()) {
            case 'waiting':
                return 'Fizetésre vár';
            case 'paid':
                return 'Fizetve';
            case 'late':
                return 'Késés';
        }
    }

    const [showAlert, setShowAlert] = useState<string | null>(null);
    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

    const getPaymentLink = (docId: number) => {
        setPaymentLoading(true);

        Axios.post(`${apiUrl}/v1/otpsp/${docId}`,
            {
                url: window.location.origin,
            },
            {
                headers: { 'Authorization': `Bearer ${authToken}` },
            }).then(res => {
                setPaymentLoading(false);

                var data = res.data;
                if (data.payment_link !== undefined && data.payment_link !== '') {
                    if (Capacitor.isNative) {
                        openInAppBrowser(data.payment_link);
                    } else {
                        window.open(data.payment_link, '_self');
                    }
                } else if (data.response !== undefined && data.response.paymentUrl !== '') {
                    if (Capacitor.isNative) {
                        openInAppBrowser(data.response.paymentUrl);
                    } else {
                        window.open(data.response.paymentUrl, '_self');
                    }
                } else {
                    if (data.message !== undefined) {
                        setShowAlert(data.message);
                    } else {
                        setShowAlert('Hiba történt a befizetés közben.');
                    }
                }
            }).catch(err => {
                setPaymentLoading(false);

                if (err.response.data.messages !== undefined) {
                    if(err.response.data.codes !== undefined) {
                        setShowAlert(err.response.data.messages[0] + '<br/><br/>Hibakód: ' + err.response.data.codes[0]);
                    } else {
                        setShowAlert(err.response.data.messages[0]);
                    }
                } else {
                    setShowAlert('Hiba történt a befizetés közben.');
                }

                checkAxiosError(err);
            });
    }

    const [showRedirectToPayment, setShowRedirectToPayment] = useState<boolean>(false);
    const [redirectToPaymentParams, setRedirectToPaymentParams] = useState<any>({
        r: null,
        s: null,
        t: null,
    });

    const openInAppBrowser = async (link: string) => {
        let browser = await InAppBrowser.create(link, '_self', 'location=yes,hidenavigationbuttons=yes,hideurlbar=yes,zoom=no,toolbarcolor=#ffffff,lefttoright=yes,closebuttoncolor=#000000');
        browser.on('loadstart').subscribe(event => {
            if (event.url.includes('/my/invoices/payment-response')) {
                browser.close();
                browser = undefined;

                let responseUrl = new URL(event.url);
                let params = new URLSearchParams(responseUrl.search);
                setRedirectToPaymentParams({
                    r: params.get('r'),
                    s: params.get('s'),
                    t: params.get('t'),
                });
                setShowRedirectToPayment(true);
            }
        });
    };

    if (showRedirectToPayment && redirectToPaymentParams.t !== null && redirectToPaymentParams.r !== null && redirectToPaymentParams.s !== null) {
        return (
            <Redirect to="/my/invoices/payment-response?t={{redirectToPaymentParams.t}}&r={{redirectToPaymentParams.r}}&s={{redirectToPaymentParams.s}}" />
        );
    }

    return (
        <PageWrapper>
            <h1 className="pageTitle">Számla</h1>

            {invoice !== null 
            ?
                <>
                    <IonRow>
                        {invoice.related.GroupNum !== "2" &&
                            <>
                                {(getStatus() !== 'paid')
                                ?
                                    <IonCol size="12" sizeMd="6">
                                        <IonButton expand="block" color="warning" onClick={() => getPaymentLink(invoice.id)}>
                                            <IonIcon icon={cardOutline} slot="start" />
                                            Fizetés
                                        </IonButton>
                                    </IonCol>
                                :
                                    <IonCol size="12">
                                        <IonText color="success">
                                            <h1 className="ion-text-center alertIcon">
                                                <IonIcon icon={checkmarkCircleOutline} />
                                            </h1>
                                            <h2 className="ion-text-center alertText">
                                                A számla befizetve.
                                            </h2>
                                        </IonText>
                                    </IonCol>
                                }
                            </>
                        }

                        {invoice.fileId &&
                            <IonCol size="12" sizeMd={(getStatus() !== 'paid') ? "6" : "12"}>
                                <IonButton href={fileUrl + "/" + invoice.fileId} target="_blank" color="success" expand="block">
                                    <IonIcon icon={documentTextOutline} slot="start" />
                                    Megnyitása
                                </IonButton>
                            </IonCol>
                        }
                    </IonRow>
                    <br/>

                    <div className="list-between">
                        <span>Számlaszám</span>
                        <span>{invoice.related.invoice_number}</span>
                    </div>
                    <div className="list-between">
                        <span>Fizetési határidő</span>
                        <span>{invoice.related.DocDueDate ? dateFormat(invoice.related.DocDueDate, 'yyyy.mm.dd HH:MM') : ' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Megjegyzés</span>
                        <span>{invoice.related.Comments??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Státusz</span>
                        <span>{getStatusText()}</span>
                    </div>
                    <div className="list-between price">
                        <span>Összeg</span>
                        <span>
                            {invoice.related.DoCTotal &&
                                <NumberFormat value={parseInt(invoice.related.DoCTotal)} displayType={'text'} thousandSeparator={" "} suffix={' Ft'} />
                            }
                        </span>
                    </div>
                    <br/>

                    {/*<div className="list-between">
                        <span>Partner azonosító</span>
                        <span>{invoice.related.CardCode??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Számlázási cím</span>
                        <span>{invoice.related.Address??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Bizonylat dátum</span>
                        <span>{invoice.related.TaxDate ? dateFormat(invoice.related.TaxDate, 'yyyy.mm.dd HH:MM') : ' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Fizetési feltétel</span>
                        <span>{invoice.related.GroupNum??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Kirendeltség azonosító</span>
                        <span>{invoice.related.BPLId??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Számla típusa</span>
                        <span>{invoice.related.U_Tipus??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Fizetve</span>
                        <span>{invoice.related.U_CPH_PaymentCreated??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Postázási cím</span>
                        <span>{invoice.related.U_PostazasiCim??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Csekkazonosító</span>
                        <span>{invoice.related.U_CheckID??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Sztornózott</span>
                        <span>{invoice.related.CANCELED??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>ÁFA végösszeg</span>
                        <span>{invoice.related.VatSum??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Bankszámlaszám</span>
                        <span>{invoice.related.U_HousBnkAct??' - '}</span>
                    </div>
                    <div className="list-between">
                        <span>Számla bizonylat típusa</span>
                        <span>{invoice.related.BizonylatTipus??' - '}</span>
                    </div>*/}

                    {invoice.invoice_data !== undefined &&
                        <>
                            {(invoice.invoice_data !== null && invoice.invoice_data.length > 0) &&
                                <>
                                    <h3 className="smallTitle">Részletek</h3>
                                    {invoice.invoice_data.map((item: any, key: any) => (
                                        <div className="list-between" key={key}>
                                            <span>{item.label??''}</span>
                                            <span>{item.value??''}</span>
                                        </div>
                                    ))}
                                    <br/>
                                </>
                            }
                        </>
                    }

                    {invoice.invoice_items_data !== undefined &&
                        <>
                            {(invoice.invoice_items_data !== null && invoice.invoice_items_data.length > 0) &&
                                <>
                                    <h3 className="smallTitle">Számla elemei</h3>
                                    {invoice.invoice_items_data.map((items: any, key: any) => (
                                        <div key={key}>
                                            {items.map((item: any, _key: any) => (
                                                <div className="list-between" key={_key}>
                                                    <span>{item.label??''}</span>
                                                    <span>{item.value??''}</span>
                                                </div>
                                            ))}
                                            <br/>
                                        </div>
                                    ))}
                                    <br/>
                                </>
                            }
                        </>
                    }

                    <br/>
                </>
            :
                <IonLoading
                    isOpen={Boolean(invoice === null)}
                    message={'Számla betöltése...'}
                    />
            }

            {paymentLoading &&
                <IonLoading
                    isOpen={paymentLoading}
                    message={'Kérem várjon...'}
                />
            }

            <IonAlert
                isOpen={Boolean(showAlert !== null)}
                onDidDismiss={() => setShowAlert(null)}
                header={'Információ'}
                message={showAlert}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
            />

        </PageWrapper>
    );
};

export default InvoicesPaymentPage;

import React, { useState } from 'react';
import PageWrapper from '../layouts/PageWrapper';
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
} from "@ionic/react";
import { useAuth } from '../../context/Auth';
import useFormValidation from '../../helpers/FormValidationHelper';
import { addApiErrors, setLoading, hasError, setInitError, ErrorsList } from '../../helpers/GlobalHelpers';

import './DatamodPage.scss';
import { saveOutline } from 'ionicons/icons';
import Axios from 'axios';
import ValidateNewPassword from '../../helpers/validators/ValidateNewPassword';
import { useUrl } from '../../context/Url';

const INIT_STATE = {
    email: '',
    password: '',
    password_new: '',
    password_new_re: '',
};

const UserPage: React.FC = () => {
    const { handleChange, values } = useFormValidation(INIT_STATE);
    const { authToken, checkAxiosError } = useAuth();
    const { apiUrl } = useUrl();
    const [ status, setStatus ] = useState({
        loading: false,
        error_list: INIT_STATE,
    });
    const [passwordSaved, setPasswordSaved] = useState<boolean>(false);

    const savePassword = async () => {
        setLoading(true, setStatus);
        setInitError(INIT_STATE, setStatus);

        const errors = ValidateNewPassword(values);
        if (hasError(errors)) {
            setLoading(false, setStatus);
            addApiErrors(errors, setStatus);
        } else {
            Axios.post(`${apiUrl}/v1/new-password`, {
                password: values.password,
                password_new: values.password_new,
                password_new_re: values.password_new_re,
            },{
                headers: { 'Authorization': `Bearer ${authToken}` },
            }).then(res => {
                setLoading(false, setStatus);
                setPasswordSaved(true);
            }).catch(err => {
                setLoading(false, setStatus);
                addApiErrors(err.response.data.message, setStatus);
                checkAxiosError(err);
            });
        }
    }

    return (
        <PageWrapper>
            <div className="hasButton">
                <h1 className="pageTitle">Jelszóváltoztatás</h1>
            </div>

            <IonCard className="datamodList ion-margin-bottom">
                <IonCardContent className="isOpen">
                    <form className="form">
                        <div>
                            <IonItem>
                                <IonLabel position="floating">Régi jelszó</IonLabel>
                                <IonInput title="Régi jelszó" onIonInput={handleChange} clearOnEdit={false} type="password" pattern="password" name="password" value={values.password} required></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Új jelszó</IonLabel>
                                <IonInput title="Új jelszó" onIonInput={handleChange} clearOnEdit={false} type="password" pattern="password" name="password_new" value={values.password_new} required></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Új jelszó mégegyszer</IonLabel>
                                <IonInput title="Új jelszó mégegyszer" onIonInput={handleChange} clearOnEdit={false} type="password" pattern="password" name="password_new_re" value={values.password_new_re} required></IonInput>
                            </IonItem>

                            <br/>
                            <ErrorsList errors={status.error_list} />
                            
                            <div className="bottomPaddingButton">
                                <IonButton color="success" onClick={() => savePassword()}>
                                    <IonIcon icon={saveOutline} slot="start" />
                                    Mentés
                                </IonButton>
                            </div>
                        </div>
                    </form>
                </IonCardContent>
            </IonCard>

            <IonLoading
                isOpen={status.loading}
                message={'Kérem várjon...'}
                />

            <IonAlert
                isOpen={passwordSaved}
                onDidDismiss={() => setPasswordSaved(false)}
                header={'Siker'}
                message={'A jelszó megváltoztott!'}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
                />   
        </PageWrapper>
    );
};

export default UserPage;

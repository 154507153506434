import React, { useState } from "react";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline, filterOutline } from "ionicons/icons";

interface Props {
    number: number;
    setFilterDefault: () => void,
}

const FilterModal: React.FC<Props> = props => {
    const { children, number } = props;
    const [ isOpen, setIsOpen ] = useState(false);

    return (
        <>
            <div className="filterModalToolbar">
                <span>Találat: <b>{number} db</b></span>
                <div>
                    <IonButton onClick={() => setIsOpen(true)} color="primary" size="small">
                        <IonIcon slot="start" icon={filterOutline} />
                        Szűrés
                    </IonButton>
                </div>
            </div>

            <IonModal onDidDismiss={() => setIsOpen(false)} isOpen={isOpen}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => setIsOpen(false)}>
                                <IonIcon slot="icon-only" icon={closeOutline} color="medium"/>
                            </IonButton>
                        </IonButtons>
                        <IonTitle>Szűrés</IonTitle>
                        {/*<IonButtons slot="end">
                            <IonButton onClick={setFilterDefault}>
                                Alaphelyzet
                            </IonButton>
                        </IonButtons>*/}
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {children}

                    <br/>
                    <div className="paddingForButton">
                        <IonButton color="success" expand="block" onClick={() => setIsOpen(false)}>
                            Szűrés
                        </IonButton>
                    </div>
                </IonContent>
            </IonModal>
        </>
    );
}

export default FilterModal;

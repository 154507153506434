import React, { useState } from 'react';
import { IonButton, IonIcon, IonLoading, IonText, useIonViewDidEnter } from '@ionic/react';
import { useAuth } from '../../context/Auth';
import PageWrapper from '../layouts/PageWrapper';
import { checkmarkCircleOutline, closeCircleOutline, hourglassOutline } from 'ionicons/icons';
import Axios from 'axios';
import { useUrl } from '../../context/Url';


const PaymentResponsePage: React.FC = () => {
    const { authToken, checkAxiosError } = useAuth();
    const { apiUrl } = useUrl();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ eventResponse, setEventResponse ] = useState<string | null>(null);
    const [ statusResponse, setStatusResponse ] = useState<string | null>(null);

    const getParams = (search: any) => {
        let hashes = search.slice(search.indexOf('?') + 1).split('&')
        return hashes.reduce((params: any, hash: any) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {[key]: val})
        }, {})
    }

    const getUrlParams = () => {
        if(isLoading) {
            let params = getParams(window.location.href);
        
            if(eventResponse === null) {
                setEventResponse(params['event']);
            }
    
            if(params['event'] === 'success') {
                sendPaymentStatus(params['r'], params['s'], params['t']);
            } else {
                setIsLoading(false);
            }
        }
    }

    const sendPaymentStatus = (rParam: string, sParam: string, tParam: string) => {
        Axios.post(`${apiUrl}/v1/response`,
        {
            r: rParam,
            s: sParam,
            t: tParam,
        },
        {
            headers: { 'Authorization': `Bearer ${authToken}` },
        }).then(res => {
            if(res.data.status !== undefined) {
                if(res.data.status !== 'INIT') {
                    setStatusResponse(res.data.status);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        }).catch(err => {
            setIsLoading(false);
            checkAxiosError(err);
        });
    }

    useIonViewDidEnter(() => {
        getUrlParams();
        setInterval(() => {
            getUrlParams();
        }, 5000);
    });

    const getStatusComponent = () => {
        switch (eventResponse) {
            case 'success':
                switch(statusResponse) {
                    case 'FINISHED':
                        return (
                            <>
                                <IonText color="success">
                                    <h1 className="ion-text-center alertIcon">
                                        <IonIcon icon={checkmarkCircleOutline} />
                                    </h1>
                                    <h2 className="ion-text-center alertText">
                                        Sikeres, befejezett tranzakció!
                                    </h2>
                                </IonText>
                            </>
                        );
                    case 'TIMEOUT':
                        return (
                            <>
                                <IonText color="danger">
                                    <h1 className="ion-text-center alertIcon">
                                        <IonIcon icon={closeCircleOutline} />
                                    </h1>
                                    <h2 className="ion-text-center alertText">
                                        Ön túllépte a tranzakció elindításának lehetséges maximális idejét.
                                    </h2>
                                </IonText>
                            </>
                        );
                    case 'CANCELLED':
                        return (
                            <>
                                <IonText color="danger">
                                    <h1 className="ion-text-center alertIcon">
                                        <IonIcon icon={closeCircleOutline} />
                                    </h1>
                                    <h2 className="ion-text-center alertText">
                                        Ön megszakította a fizetést.
                                    </h2>
                                </IonText>
                            </>
                        );
                    case 'AUTHORIZED':
                    case 'INFRAUD':
                    case 'INPAYMENT':
                        return (
                            <>
                                <IonText color="warning">
                                    <h1 className="ion-text-center alertIcon">
                                        <IonIcon icon={hourglassOutline} />
                                    </h1>
                                    <h2 className="ion-text-center alertText">
                                        Kérem várjon, feldolgozás folyamatban...
                                    </h2>
                                </IonText>
                            </>
                        );
                    case 'REFUND':
                    case 'REVERSED':
                    case 'FRAUD':
                    case 'NOTAUTHORIZED':
                    default:
                        return (
                            <>
                                <IonText color="danger">
                                    <h1 className="ion-text-center alertIcon">
                                        <IonIcon icon={closeCircleOutline} />
                                    </h1>
                                    <h2 className="ion-text-center alertText">
                                        Hiba történt a tranzakció lekérdezésekor.
                                    </h2>
                                </IonText>
                            </>
                        );
                }
            case 'timeout':
                return (
                    <>
                        <IonText color="danger">
                            <h1 className="ion-text-center alertIcon">
                                <IonIcon icon={closeCircleOutline} />
                            </h1>
                            <h2 className="ion-text-center alertText">
                                Sikertelen authorizáció.
                            </h2>
                        </IonText>
                    </>
                );
            case 'cancel':
                return (
                    <>
                        <IonText color="danger">
                            <h1 className="ion-text-center alertIcon">
                                <IonIcon icon={closeCircleOutline} />
                            </h1>
                            <h2 className="ion-text-center alertText">
                                Ön megszakította a fizetést.
                            </h2>
                        </IonText>
                    </>
                );
            case 'fail':
            default:
                return (
                    <>
                        <IonText color="danger">
                            <h1 className="ion-text-center alertIcon">
                                <IonIcon icon={closeCircleOutline} />
                            </h1>
                            <h2 className="ion-text-center alertText">
                                Sikertelen kártya authorizáció, vagy sikertelen 3DS ellenőrzés.
                            </h2>
                        </IonText>
                    </>
                );
        }
    }

    return (
        <PageWrapper className="smallCenterContentDesktop">
            {!isLoading &&
                <>
                    {getStatusComponent()}
                    <IonButton expand="block" color="success" routerLink="/my/invoices" routerDirection="back">Számláim</IonButton>
                    <IonButton expand="block" color="medium" routerLink="/my/dashboard" routerDirection="root">Vissza a főoldalra</IonButton>
                </>
            }

            {isLoading &&
                <IonLoading
                    isOpen={isLoading}
                    message={'Kérem várjon...'}
                />
            }
        </PageWrapper>
    );
};

export default PaymentResponsePage;

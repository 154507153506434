import React, { useState } from 'react';
import {
    IonContent,
    IonGrid,
    IonPage,
} from "@ionic/react";
import { Redirect } from 'react-router-dom';
import { useAuth } from '../context/Auth';
import MyPatentHeader from '../components/MyPatentHeader';

import AssignWelcomePage from './assign/AssignWelcomePage';
import AssignTelPage from './assign/AssignTelPage';
import AssignSmsPage from './assign/AssignSmsPage';
import AssignWaitPage from './assign/AssignWaitPage';

const AssignPage: React.FC = () => {
    const { loggedIn } = useAuth();
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ relation, setRealtion ] = useState<any>([]);

    const updatePhone = (phone: string) => {
        let local_rel = relation;
        local_rel.client_phone_number = phone;
        setRealtion(local_rel);
    }

    if (loggedIn !== null && loggedIn === false) {
        return <Redirect to="/" />;
    }
    return (
        <IonPage>
            <IonContent id="loginPage">
                <MyPatentHeader isLogoHidden={false} />

                <IonContent className="backgroundTransparent">
                    <IonGrid className="ion-padding" id="ionGridInner" fixed>
                        <div className="page-content">
                            {(() => {
                                switch (currentPage) {
                                    default:
                                    case 0:
                                        return <AssignWelcomePage setCurrentPage={(num) => setCurrentPage(num)} setRelation={(rel) => setRealtion(rel)} />;
                                    case 1:
                                        return <AssignTelPage setCurrentPage={(num) => setCurrentPage(num)} relation={relation} updatePhone={(phone) => updatePhone(phone)} />;
                                    case 2:
                                        return <AssignSmsPage setCurrentPage={(num) => setCurrentPage(num)} relation={relation} />;
                                    case 3:
                                        return <AssignWaitPage setCurrentPage={(num) => setCurrentPage(num)} relation={relation} />;
                                }
                            })()}
                        </div>
                    </IonGrid>
                </IonContent>
            </IonContent>
        </IonPage>
    );
}

export default AssignPage;


import React, { useContext } from 'react';

export const AuthContext = React.createContext({
    loggedIn: null,
    authToken: '',
    checkAxiosError: (error: any) => {},
    setLogout: () => {},
    assigns: [],
    assignsLoaded: false,
    setAssigns: (assign: any) => {},
    userInfo: null,
    setUserInfo: (userInfo: any) => {},
});

export function useAuth() {
    return useContext(AuthContext);
}

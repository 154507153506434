import React from "react";
import logo from '../assets/images/logo.svg'
import { IonMenuButton, IonRouterLink } from "@ionic/react";
import { useAuth } from "../context/Auth";

interface Props {
    isLogoHidden: boolean;
    hasMenu?: boolean;
}

const MyPatentHeader: React.FC<Props> = props => {
    const { loggedIn, assigns, assignsLoaded } = useAuth();

    return (
        <div id="myPatent-Header" className={props.hasMenu ? 'hasMenu' : ''}>
            <div id="myPatent-Header-wrapper" className={props.isLogoHidden ? 'hidden' : ''}>
                {props.hasMenu 
                ?
                    <IonMenuButton /> 
                : 
                    <div></div>
                }
                
                
                {(loggedIn !== null && loggedIn === true && assignsLoaded && assigns.length > 0)
                ?
                    <IonRouterLink routerLink="/my/dashboard" routerDirection="root" className="logoDiv">
                        <img src={logo} alt="Logo" />
                        <span>A BIZTONSÁG MINŐSÉGE</span>
                    </IonRouterLink>
                :
                    <IonRouterLink routerLink="/" routerDirection="root" className="logoDiv">
                        <img src={logo} alt="Logo" />
                        <span>A BIZTONSÁG MINŐSÉGE</span>
                    </IonRouterLink>
                }
            </div>
        </div>
    );
}

export default MyPatentHeader;

import React from 'react';
import {
    IonItem,
    IonLabel,
    IonSkeletonText
} from "@ionic/react";

import './AccordionItem.scss';

interface Props {
    count: number;
}

const SkeletonProfileItem: React.FC<Props> = props => {
    const { count } = props;

    const getItems = () => {
        let i = 0;
        let items = [];
        while (i < count) {
            i++;
            let rand = 20 + Math.random() * (80 - 20);
            items.push(
                <div className="inputWithModalWrapper" key={i}>
                    <IonItem className="inputWithModal" lines="none">
                        <IonLabel color="secondary">
                            <IonSkeletonText animated style={{ width: rand + '%' }} />
                        </IonLabel>
                        <IonLabel color="secondary">
                            <IonSkeletonText animated style={{ width: rand + '%' }} />
                        </IonLabel>
                    </IonItem>
                </div>
            );
        }
        return items;
    }

    return (
        <>
            {getItems()}
        </>
    );
};

export default SkeletonProfileItem;

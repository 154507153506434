import React, { useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonLoading } from '@ionic/react';

import useFormValidation from '../../helpers/FormValidationHelper';
import ValidateTelefonszam from '../../helpers/validators/ValidateTelefonszam';
import { useAuth } from '../../context/Auth';
import Axios from 'axios';
import { setLoading, setInitError, hasError, addApiErrors, ErrorsList } from '../../helpers/GlobalHelpers';
import { isMobileNumber } from '../../helpers/MobilePhoneHelper';
import { useUrl } from '../../context/Url';

const INIT_STATE = {
    telefonszam: '',
};

interface Props {
    setCurrentPage: (num: number) => void;
    relation: any;
    updatePhone: (phone: string) => void;
}

const AssignTelPage: React.FC<Props> = ({ setCurrentPage, relation, updatePhone }) => {
    const { authToken, assigns, checkAxiosError } = useAuth();
    const { handleChange, values } = useFormValidation(INIT_STATE);
    const { apiUrl } = useUrl();
    const [status, setStatus] = useState({
        loading: false,
        error_list: INIT_STATE,
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === 'Enter') {
            handleSendTelefonszam();
        }
    }

    const handleSendTelefonszam = async () => {
        setLoading(true, setStatus);
        setInitError(INIT_STATE, setStatus);

        const errors = ValidateTelefonszam(values);
        if (hasError(errors)) {
            setLoading(false, setStatus);
            addApiErrors(errors, setStatus);
        } else {
            Axios.post(`${apiUrl}/v1/assign/new-phone`, {
                relation_id: relation.id,
                phone: values.telefonszam,
            },
            {
                headers: {'Authorization': `Bearer ${authToken}`},
            }).then(res => {
                setLoading(false, setStatus);
                updatePhone(values.telefonszam);
                setCurrentPage(3);
            }).catch(err => {
                checkAxiosError(err);
                setLoading(false, setStatus);
                if(!err.response) {
                    addApiErrors('Hálózati hiba.', setStatus);
                } else {
                    addApiErrors(err.response.data.message, setStatus);
                }
            });
        }
    }

    return (
        <>
            {(relation.verify_phone_number === undefined || relation.verify_phone_number === null || relation.verify_phone_number === '') ?
                <>
                    <h4>Hiányzó telefonszám</h4>
                    <p>
                        Az Ön által megadott azonosítóhoz nem tárol a rendszerünk telefonszámot.
                        <br/> <br/>
                        Kérjük adja meg azt a telefonszámot amin kollégánk keresheti Önt jóváhagyás céljából.
                    </p>
                </>
            :
                <>
                    {isMobileNumber(relation.verify_phone_number) ?
                        <>
                            <h4>Segítésget kér?</h4>
                            <p>
                                Kérjük adja meg azt a telefonszámot amin kollégánk keresheti Önt!
                            </p>
                        </>
                    :
                        <>
                            <h4>Figyelem</h4>
                            <p>
                                A rendszerben tárolt telefonszámra nem tudunk SMS megerősítő kódot küldeni. 
                                <br/> <br/>
                                Kérjük adja meg azt a telefonszámot amin kollégánk keresheti Önt jóváhagyás céljából.
                            </p>
                        </>
                    }
                </>
            }


            <div className="form">
                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.telefonszam ? 'error' : '')}>
                    <IonLabel position="floating">Telefonszám</IonLabel>
                    <IonInput onKeyDown={handleKeyDown} clearOnEdit={false} type="text" inputmode="text" name="telefonszam" onIonChange={handleChange} value={values.telefonszam} required autofocus={true}></IonInput>
                </IonItem>
            </div>
            
            <ErrorsList errors={status.error_list} />

            <IonButton color="success" expand="block" onClick={handleSendTelefonszam}>Telefonszám beküldése</IonButton>

            {assigns.length !== 0 && 
                <IonButton color="medium" expand="block" routerLink="/my/dashboard" routerDirection="none">Vissza a főoldalra</IonButton>
            }

            <IonLoading
                isOpen={status.loading}
                message={'Kérem várjon...'}
            />
        </>
    );
};

export default AssignTelPage;

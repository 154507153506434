import React from 'react';
import {
    IonAvatar,
    IonItem,
    IonLabel,
    IonSkeletonText
} from "@ionic/react";

import './AccordionItem.scss';

interface Props {
    count: number;
}

const SkeletonAccordionItem: React.FC<Props> = props => {
    const { count } = props;

    const getItems = () => {
        let i = 0;
        let items = [];
        while (i < count) {
            i++;
            items.push(
                <div className="accordionItemWrapper" key={i}>
                    <IonItem className="accordionItem" lines="none">
                        <IonAvatar slot="start">
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            <h2 className="title read">
                                <IonSkeletonText animated style={{ width: '25%' }} />
                            </h2>
                            <p>
                                <IonSkeletonText animated style={{ width: '40%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                </div>
            );
        }
        return items;
    }

    return (
        <>
            {getItems()}
        </>
    );
};

export default SkeletonAccordionItem;

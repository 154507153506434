import React, { useEffect, useState } from 'react';
import PageWrapper from '../layouts/PageWrapper';
import { IonRow, IonCol, IonCard, IonIcon, IonBadge, IonButton } from '@ionic/react';

import { documentOutline, notificationsOutline, optionsOutline, fileTrayFullOutline, laptopOutline, readerOutline, warning, checkmarkCircle } from 'ionicons/icons';

import { useNotification } from '../../context/Notifications';
import NumberFormat from 'react-number-format';

import Axios from 'axios';
import { useAuth } from '../../context/Auth';
import { useUrl } from '../../context/Url';

import './DashboardPage.scss';

const DashboardPage: React.FC = () => {
    const { unreadNotifictaions } = useNotification();
    const { authToken, checkAxiosError } = useAuth();
    const { apiUrl } = useUrl();

    const [unpaidInvoices, setUnpaidInvoices] = useState<any>(null);

    const getUnpaidInvoices = () => {
        Axios.get(`${apiUrl}/v1/invoices/unpaid`,
        {
            headers: { 'Authorization': `Bearer ${authToken}` },
        }).then(res => {
            if(res.data.error === false) {
                setUnpaidInvoices(res.data.data);
            }
        }).catch(err => {
            checkAxiosError(err);
        });
    }

    useEffect(() => {
        if(unpaidInvoices === null) {
            getUnpaidInvoices();
        }
    }, [unpaidInvoices])

    return (
        <PageWrapper>
            <div className="hasButton">
                <h1 className="pageTitle">Főoldal</h1>
                <IonButton size="small" color="success" routerLink="/assign" routerDirection="none">Új hozzárendelés</IonButton>
            </div>

            <div id="desktopWelcome">
                <h2>Üdv a myPatent alkalmazásban!</h2>
                <p>Bármilyen hibát talált kérem jelezze az <a href="mailto:info@mypatent.hu">info@mypatent.hu</a> címen! Köszönjük!</p>
                {/*<div className="inlineButtons">
                    <IonButton color="light" size="small" routerLink="/assign" routerDirection="forward">
                        Új hozzárendelés
                    </IonButton>
                    <IonButton color="light" size="small" routerLink="/my/documents" routerDirection="forward">
                        Dokumentumaim
                    </IonButton>
                    <IonButton color="light" size="small" routerLink="/my/invoices" routerDirection="forward">
                        Számláim
                    </IonButton>
                    <IonButton color="light" size="small" routerLink="/my/profile" routerDirection="forward">
                        Adataim
                    </IonButton>
                </div>*/}
            </div>

            {unpaidInvoices !== null &&
                <IonRow>
                    <IonCol size="12">
                        <IonCard button={true} className="cardButton alert" routerLink="/my/invoices" routerDirection="forward">
                        {unpaidInvoices > 0
                        ?
                            <div id="invoiceAlert">
                                <IonIcon icon={warning} />
                                <h2>Fizetetlen számlák: <strong><NumberFormat value={unpaidInvoices} displayType={'text'} thousandSeparator={" "} suffix={' Ft'} /></strong></h2>
                            </div>
                        :
                            <div id="invoiceAlert" className="success">
                                <IonIcon icon={checkmarkCircle} />
                                <h2>Nincs fizetetlen számla!</h2>
                            </div>
                        }
                        </IonCard>
                    </IonCol>
                </IonRow>
            }

            <IonRow>
                <IonCol size="6" sizeMd="3" sizeLg="4">
                    <IonCard button={true} className="cardButton" routerLink="/my/notifications" routerDirection="forward">
                        {unreadNotifictaions !== 0 &&
                            <IonBadge color="danger">{unreadNotifictaions}</IonBadge>
                        }
                        <IonIcon icon={notificationsOutline} />
                        <h2>Értesítések</h2>
                    </IonCard>
                </IonCol>
                <IonCol size="6" sizeMd="3" sizeLg="4">
                    <IonCard button={true} className="cardButton" routerLink="/my/documents" routerDirection="forward">
                        <IonIcon icon={documentOutline} />
                        <h2>Dokumentumtár</h2>
                    </IonCard>
                </IonCol>
                <IonCol size="6" sizeMd="3" sizeLg="4">
                    <IonCard button={true} className="cardButton" routerLink="/my/profile" routerDirection="forward">
                        <IonIcon icon={readerOutline} />
                        <h2>Adataim</h2>
                    </IonCard>
                </IonCol>
                <IonCol size="6" sizeMd="3" sizeLg="4">
                    <IonCard button={true} className="cardButton" routerLink="/my/invoices" routerDirection="forward">
                        <IonIcon icon={fileTrayFullOutline} />
                        <h2>Számláim</h2>
                    </IonCard>
                </IonCol>
                <IonCol size="6" sizeMd="3" sizeLg="4">
                    <IonCard button={true} className="cardButton" routerLink="/my/subsystems" routerDirection="forward">
                        <IonIcon icon={laptopOutline} />
                        <h2>Alrendszerek</h2>
                    </IonCard>
                </IonCol>
                <IonCol size="6" sizeMd="3" sizeLg="4">
                    <IonCard button={true} className="cardButton" routerLink="/my/profile" routerDirection="forward">
                        <IonIcon icon={optionsOutline} />
                        <h2>Adatmódosítás</h2>
                    </IonCard>
                </IonCol>
            </IonRow>
        </PageWrapper>
    );
};

export default DashboardPage;

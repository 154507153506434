import React, { useState, useEffect } from 'react';
import PageWrapper from '../layouts/PageWrapper';
import {
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    IonIcon,
    IonAlert,
    IonLoading,
} from "@ionic/react";
import { RefresherEventDetail } from '@ionic/core';

import { cardOutline, caretDown, documentTextOutline, list } from 'ionicons/icons';
import SkeletonAccordionItem from '../../components/SkeletonAccordionItem';
import EmptyText from '../../components/EmptyText';
import FilterModal from '../../components/FilterModal';
import { useAuth } from '../../context/Auth';
import { Filter, FilterJsonObject, FilterObject, FilterQueryObject, getFiltersArray } from '../../helpers/FilterHelper';
import Axios from 'axios';
import InvoiceAccordionItem from '../../components/InvoiceAccordionItem';
import NumberFormat from 'react-number-format';
import { Capacitor } from "@capacitor/core";
import { Redirect } from 'react-router';
import { useUrl } from '../../context/Url';
import { InAppBrowser } from '@ionic-native/in-app-browser';

import simplepay_w360 from '../../assets/images/simplepay_w360.png';

interface Documents {
    id: number;
    type: string;
    createdAt: string;
    related: any;
    transactions: any;
    fileId?: number | null;
    title?: string;
}

const InvoicesPage: React.FC = () => {
    const { authToken, checkAxiosError } = useAuth();
    const { apiUrl, fileUrl } = useUrl();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [disablePullToRefresh, setDisablePullToRefresh] = useState<boolean>(true);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(true);

    const [filters, setFilters] = useState([]);
    const [skip, setSkip] = useState<number>(0);

    const [documents, setDocuments] = useState<Documents[]>([]);

    var dateFormat = require('dateformat');

    const pullToRefresh = (event?: CustomEvent<RefresherEventDetail> | null) => {
        getData(false, true).then(() => {
            if (event !== null) {
                event.detail.complete();
            }
        });
    }

    const infiniteScrollNext = (event: CustomEvent<void>) => {
        getData(true).then(() => {
            (event.target as HTMLIonInfiniteScrollElement).complete();
        });
    }

    const getData = async (next: boolean = false, forceRefresh: boolean = false) => {
        var filterQuery: FilterJsonObject[] = [];

        if (filters.length > 0) {
            filters.forEach((_fil: any) => {
                filterQuery.push({
                    key: _fil.key,
                    value: _fil.value,
                } as FilterJsonObject);
            });
        }

        var queryObject: FilterQueryObject = {
            skip: (next ? skip : 0),
            filters: filterQuery,
        };

        setDisablePullToRefresh(true);

        if (forceRefresh) {
            setDocuments([]);
            setIsLoading(true);
            setSkip(0);
        }

        await Axios.post(`${apiUrl}/v1/invoices/view`,
            queryObject,
            {
                headers: { 'Authorization': `Bearer ${authToken}` },
            }).then(res => {
                var response = res.data.data;

                if (response && response.length > 0) {
                    if (response.length < 15) {
                        // Ha kevesebb mint 15 talált van akkor nem kell az infinite scroll mert úgy sem fog többet találni.
                        setDisableInfiniteScroll(true);
                    } else {
                        setDisableInfiniteScroll(false);
                        setSkip(prevState => prevState + 15);
                    }

                    response.forEach((document: any) => {
                        if (document.related !== null) {
                            var _document: Documents = {
                                id: document.id,
                                type: document.related_type,
                                createdAt: document.created_at,
                                fileId: document.file_id,
                                related: document.related,
                                transactions: document.transactions,
                                title: document.mypatent_title,
                            };

                            setDocuments(prevState => (
                                [
                                    ...prevState,
                                    _document,
                                ]
                            ));
                        }
                    });
                } else {
                    setDisableInfiniteScroll(true);
                }

                setIsLoading(false);
                setDisablePullToRefresh(false);
            }).catch(err => {
                checkAxiosError(err);
            });
    }

    /* Filters */
    const setFilterDefault = () => { }

    const setFilterValue = (name: string, value: string) => {
        const nextState = filters.map((item: FilterObject) => item.key === name ? { ...item, value: value } : item);
        setFilters(nextState);
    }

    const getFilters = () => {
        if (filters.length === 0) {
            Axios.get(`${apiUrl}/v1/invoices/filters`,
                {
                    headers: { 'Authorization': `Bearer ${authToken}` },
                }).then(res => {
                    if (res.data.data && res.data.data.length > 0) {
                        var _filters = getFiltersArray(res.data.data);
                        setFilters(_filters);
                    }
                }).catch(err => {
                    checkAxiosError(err);
                });
        }
    }

    useEffect(() => {
        // Első betöltésnél kérje le a filtereket
        getFilters();
    }, []);

    useEffect(() => {
        // Ha változtatnak a szűrésen akkor firssítsük le a listát teljesen
        if (filters.length > 0) {
            getData(false, true);
        }
    }, [filters]);

    const [showAlert, setShowAlert] = useState<string | null>(null);
    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

    const getPaymentLink = (docId: number) => {
        setPaymentLoading(true);

        Axios.post(`${apiUrl}/v1/otpsp/${docId}`,
            {
                url: window.location.origin,
            },
            {
                headers: { 'Authorization': `Bearer ${authToken}` },
            }).then(res => {
                setPaymentLoading(false);

                var data = res.data;
                if (data.payment_link !== undefined && data.payment_link !== '') {
                    if (Capacitor.isNative) {
                        openInAppBrowser(data.payment_link);
                    } else {
                        window.open(data.payment_link, '_self');
                    }
                } else if (data.response !== undefined && data.response.paymentUrl !== '') {
                    if (Capacitor.isNative) {
                        openInAppBrowser(data.response.paymentUrl);
                    } else {
                        window.open(data.response.paymentUrl, '_self');
                    }
                } else {
                    if (data.message !== undefined) {
                        setShowAlert(data.message);
                    } else {
                        setShowAlert('Hiba történt a befizetés közben.');
                    }
                }
            }).catch(err => {
                setPaymentLoading(false);

                if (err.response.data.messages !== undefined) {
                    if(err.response.data.codes !== undefined) {
                        setShowAlert(err.response.data.messages[0] + '<br/><br/>Hibakód: ' + err.response.data.codes[0]);
                    } else {
                        setShowAlert(err.response.data.messages[0]);
                    }
                } else {
                    setShowAlert('Hiba történt a befizetés közben.');
                }

                checkAxiosError(err);
            });
    }

    const [showRedirectToPayment, setShowRedirectToPayment] = useState<boolean>(false);
    const [redirectToPaymentParams, setRedirectToPaymentParams] = useState<any>({
        r: null,
        s: null,
        t: null,
    });

    const openInAppBrowser = async (link: string) => {
        let browser = await InAppBrowser.create(link, '_self', 'location=yes,hidenavigationbuttons=yes,hideurlbar=yes,zoom=no,toolbarcolor=#ffffff,lefttoright=yes,closebuttoncolor=#000000');
        browser.on('loadstart').subscribe(event => {
            if (event.url.includes('/my/invoices/payment-response')) {
                browser.close();
                browser = undefined;

                let responseUrl = new URL(event.url);
                let params = new URLSearchParams(responseUrl.search);
                setRedirectToPaymentParams({
                    r: params.get('r'),
                    s: params.get('s'),
                    t: params.get('t'),
                });
                setShowRedirectToPayment(true);
            }
        });
    };

    if (showRedirectToPayment && redirectToPaymentParams.t !== null && redirectToPaymentParams.r !== null && redirectToPaymentParams.s !== null) {
        return (
            <Redirect to="/my/invoices/payment-response?t={{redirectToPaymentParams.t}}&r={{redirectToPaymentParams.r}}&s={{redirectToPaymentParams.s}}" />
        );
    }

    return (
        <PageWrapper>
            <IonRefresher disabled={disablePullToRefresh} slot="fixed" onIonRefresh={pullToRefresh}>
                <IonRefresherContent
                    pullingIcon={caretDown}
                    pullingText=""
                    refreshingSpinner="crescent"
                    refreshingText="">
                </IonRefresherContent>
            </IonRefresher>

            <div className="hasButton">
                <h1 className="pageTitle">Számláim</h1>
                <IonButton size="small" className="hideOnMobile" color="success" disabled={disablePullToRefresh} onClick={() => pullToRefresh(null)}>Frissítés</IonButton>
                <a rel="noopener noreferrer" href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank" id="simpleLogo">
                    <img width="180" height="30" src={simplepay_w360} title=" SimplePay - Online bankkártyás fizetés" alt=" SimplePay vásárlói tájékoztató" />
                </a>
            </div>

            {(filters.length > 0) &&
                <>
                    <FilterModal number={documents.length ?? 0} setFilterDefault={setFilterDefault}>
                        {filters.map((filter, key) => (
                            <Filter key={key} filter={filter} setFilterValue={(name, value) => setFilterValue(name, value)} />
                        ))}
                    </FilterModal>
                    <br />
                </>
            }

            {(documents.length > 0) ?
                (
                    <>
                        {documents
                            .map(document => (
                                <InvoiceAccordionItem
                                    key={document.id}
                                    price={document.related.DoCTotal ? parseInt(document.related.DoCTotal) : 0}
                                    docDueDate={document.related.DocDueDate}
                                    type={document.type}
                                    createdAt={document.createdAt}
                                    related={document.related}
                                    transactions={document.transactions}
                                >
                                    <div>
                                        <p>{document.related.Comments ?? ''}</p>
                                    </div>
                                    <br />
                                    <div className="list-between">
                                        <span>Számlaszám</span>
                                        <span>{document.related.invoice_number}</span>
                                    </div>
                                    <div className="list-between">
                                        <span>Fizetési határidő</span>
                                        <span>
                                            {document.related.DocDueDate ? dateFormat(document.related.DocDueDate, 'yyyy.mm.dd') : ''}
                                        </span>
                                    </div>
                                    <div className="list-between price">
                                        <span>Összeg</span>
                                        <span>
                                            {document.related.DoCTotal &&
                                                <NumberFormat value={parseInt(document.related.DoCTotal)} displayType={'text'} thousandSeparator={" "} suffix={' Ft'} />
                                            }
                                        </span>
                                    </div>
                                    <br />
                                    <div className="rightButton">
                                        <IonButton size="small" routerLink={"/my/invoices/payment/" + document.id} routerDirection="forward" color="primary">
                                            <IonIcon icon={list} slot="start" />
                                            Részletek
                                        </IonButton>
                                        {document.fileId &&
                                            <>
                                                <IonButton size="small" href={fileUrl + "/" + document.fileId} target="_blank" color="success">
                                                    <IonIcon icon={documentTextOutline} slot="start" />
                                                    Megnyitása
                                                </IonButton>
                                            </>
                                        }
                                        
                                        {document.related.GroupNum !== "2" &&
                                            <>
                                                {(document.transactions !== undefined && (document.transactions.length === 0 || (document.transactions[0] !== undefined && document.transactions[0].status !== 'FINISHED'))) &&
                                                    <>
                                                        <IonButton size="small" color="warning" onClick={() => getPaymentLink(document.id)}>
                                                            <IonIcon icon={cardOutline} slot="start" />
                                                            Fizetés
                                                        </IonButton>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </InvoiceAccordionItem>

                            ))
                        }
                    </>
                )
                :
                (isLoading ?
                    <SkeletonAccordionItem count={8} />
                    :
                    <EmptyText text="Nincs számla" subtext="Az Ön számlái ezen a felületen fognak megjelenni." />
                )
            }

            {(!isLoading && disableInfiniteScroll && documents.length > 0) &&
                <EmptyText text="Nincs több találat..." subtext="" />
            }


            {paymentLoading &&
                <IonLoading
                    isOpen={paymentLoading}
                    message={'Kérem várjon...'}
                />
            }

            <IonAlert
                isOpen={Boolean(showAlert !== null)}
                onDidDismiss={() => setShowAlert(null)}
                header={'Információ'}
                message={showAlert}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
            />

            <IonInfiniteScroll threshold="100px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e: CustomEvent<void>) => infiniteScrollNext(e)}>
                <IonInfiniteScrollContent
                    loadingSpinner="crescent"
                    loadingText="További elemek betöltése">
                </IonInfiniteScrollContent>
            </IonInfiniteScroll>

        </PageWrapper>
    );
};

export default InvoicesPage;

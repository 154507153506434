
import React, { useContext } from 'react';

export const UrlContext = React.createContext({
    apiUrl: process.env.REACT_APP_MYPATENT_API_URL,
    setApiUrl: (url: string) => {},
    fileUrl: process.env.REACT_APP_MYPATENT_FILE_URL,
    setFileUrl: (url: string) => {},
    isDev: false, 
    setDevOrProd: () => {},
});

export function useUrl() {
    return useContext(UrlContext);
}

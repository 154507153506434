import React, { useState } from 'react';
import {
    IonContent,
    IonGrid,
    IonButton,
    IonLoading,
    IonInput,
    IonItem,
    IonPage,
    IonLabel,
} from "@ionic/react";
import { Redirect, useParams } from 'react-router-dom';
import { useAuth } from '../context/Auth';
import Axios from 'axios';

import useFormValidation from '../helpers/FormValidationHelper';
import ValidatePasswordRecoveryNew from '../helpers/validators/ValidatePasswordRecoveryNew';
import MyPatentHeader from '../components/MyPatentHeader';
import { setLoading, setInitError, hasError, addApiErrors, ErrorsList } from '../helpers/GlobalHelpers';

import logo from '../assets/images/logo.svg';
import './LoginPage.scss';
import { useUrl } from '../context/Url';

interface Props {
    onLogin: (login: boolean, token: string) => void;
}

const INIT_STATE = {
    password: '',
    passwordVerify: '',
};

const PasswordRecoveryPage: React.FC<Props> = ({ onLogin }) => {
    const { hash, email } = useParams();
    const { handleChange, values } = useFormValidation(INIT_STATE);
    const { apiUrl } = useUrl();
    const { loggedIn } = useAuth();
    const [status, setStatus] = useState({
        loading: false,
        error_list: INIT_STATE,
    });

    const handleSendPasswordRecovery = async () => {
        setLoading(true, setStatus);
        setInitError(INIT_STATE, setStatus);

        const errors = ValidatePasswordRecoveryNew(values);
        if (hasError(errors)) {
            setLoading(false, setStatus);
            addApiErrors(errors, setStatus);
        } else {
            Axios.post(`${apiUrl}/v1/password-recovery`, {
                password: values.password,
                password_confirmation: values.passwordVerify,
                hash: hash,
                email: email,
            }).then(res => {
                setLoading(false, setStatus);
                onLogin(true, res.data.access_token);
            }).catch(err => {
                setLoading(false, setStatus);
                if(!err.response) {
                    addApiErrors('Hálózati hiba.', setStatus);
                } else {
                    addApiErrors(err.response.data.message, setStatus);
                }
            });
        }
    }

    if (!hash) {
        return <Redirect to="/login" />;
    }

    if (loggedIn !== null && loggedIn === true) {
        return <Redirect to="/my/dashboard" />;
    }

    return (
        <IonPage>
            <IonContent id="loginPage">
                <MyPatentHeader isLogoHidden={false} />

                <IonContent className="backgroundTransparent">
                    <IonGrid className="ion-padding" id="ionGridInner" fixed>
                        <div className="page-content">
                            <img src={logo} alt="myPatent Logo" className="desktopLogo" />

                            <h1 className="pageTitle">Új jelszó</h1>

                            <div className="form">
                            <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.password ? 'error' : '')}>
                                    <IonLabel position="floating">Jelszó</IonLabel>
                                    <IonInput clearOnEdit={false} type="password" name="password" onIonChange={handleChange} value={values.password} required></IonInput>
                                </IonItem>
                                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.passwordVerify ? 'error' : '')}>
                                    <IonLabel position="floating">Jelszó újra</IonLabel>
                                    <IonInput clearOnEdit={false} type="password" name="passwordVerify" onIonChange={handleChange} value={values.passwordVerify} required></IonInput>
                                </IonItem>

                                <ErrorsList errors={status.error_list} />

                                <div className="ion-text-center">
                                    <IonButton size="large" expand="block" onClick={handleSendPasswordRecovery}>Jelszó megváltoztatása</IonButton>
                                </div>
                            </div>

                            <IonLoading
                                isOpen={status.loading}
                                message={'Kérem várjon...'}
                            />
                        </div>
                    </IonGrid>
                </IonContent>
            </IonContent>
        </IonPage>
    );
}

export default PasswordRecoveryPage;

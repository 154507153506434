import React, { useState, useEffect } from 'react';
import PageWrapper from '../layouts/PageWrapper';
import {
    IonAlert,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonDatetime,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar,
    useIonViewDidEnter,
} from "@ionic/react";
import SkeletonProfileItem from '../../components/SkeletonProfileItem';
import { RefresherEventDetail } from '@ionic/core';

import Axios from 'axios';
import { useAuth } from '../../context/Auth';

import { backspace, pencilSharp, caretDown, chevronDownOutline, ellipsisVertical, homeOutline, mailOpenOutline, walletOutline } from 'ionicons/icons';

import './DatamodPage.scss';
import EmptyText from '../../components/EmptyText';
import { Redirect } from 'react-router';
import { useUrl } from '../../context/Url';

interface InputProps {
    modelType: string;
    modelId: number|null;
    type: string;
    key: string;
    value: string;
    label: string;
    oldValue: string;
    datamodValue: string;
    edit: boolean;
    hasChanged: boolean;
    newlyAdded?: boolean;
}

interface InputGroupsProps {
    group_key: string;
    group_name: string;
    inputs: InputProps[]|null;
    inputBoxes: any|null;
    repeat: boolean;
    hasChanged: boolean;
    defaultEditFields: any;
    isOpen: boolean;
}

interface FilterProps {
    type: string;
    base_data_id: number;
    document_id: number|null;
    label: string;
}

const DatamodPage: React.FC = () => {
    const { authToken, checkAxiosError } = useAuth();
    const { apiUrl } = useUrl();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<number>(0);

    const [updateCompleted, setUpdateCompleted] = useState<boolean>(false);

    
    const [resetLoading, setResetLoading] = useState<boolean>(false);
    const [showResetDatamodsAlert, setShowResetDatamodsAlert] = useState<boolean>(false);
    const [showResetDatamodsDoneAlert, setShowResetDatamodsDoneAlert] = useState<string|null>(null);
    const [showPopover, setShowPopover] = useState<{open: boolean, event: Event | undefined}>({
        open: false,
        event: undefined,
    });

    /* Filters - Start */
    const [ filters, setFilters ] = useState<FilterProps[]>([]);
    const [ selectedFilter, setSelectedFilter ] = useState<number>(0);
    const [ alertSelectedFilter, setAlertSelectedFilter ] = useState<number|null>(null);

    const setSelectedFilterWithAlert = (id: number) => {
        let count = 0;
        inputGroups.forEach((_group: any, _groupKey: any) => {
            if(_group.hasChanged) {
                count++;
            }
        });

        if(count > 0) {
            setAlertSelectedFilter(id);
        } else {
            setSelectedFilter(id);
        }
    }

    const [ disableFilter, setDisableFilter ] = useState<boolean>(true);

    const [ noDataMod, setNoDataMod ] = useState<boolean>(false);

    const getFilters = () => {
        Axios.get(`${apiUrl}/v1/datamod-filters`,
        {
            headers: { 'Authorization': `Bearer ${authToken}` },
        }).then(res => {
            if (res.data.data && res.data.data.length > 0) {
                if(res.data.data && res.data.data.length > 0) {
                    res.data.data.forEach((_fil: any) => {
                        setFilters(prevState => [
                            ...prevState,
                            {
                                type: _fil.type,
                                base_data_id: _fil.base_data_id,
                                document_id: _fil.document_id,
                                label: _fil.label,
                            }
                        ]);
                    });
                }
                setDisableFilter(false);
            } else {
                setNoDataMod(true);
            }
        }).catch(err => {
            checkAxiosError(err);
        });
    }
    /* Filters - End */
    
    /* DatamodInputs - Start */
    const [ inputGroups, setInputGroups ] = useState<InputGroupsProps[]>([]);
    const [ hasUpdated, setHasUpdated ] = useState<boolean>(false);
    const [ gettingInputs, setGettingInputs ] = useState<boolean>(false);

    const getDatamodInputs = (base_data_id: number, document_id?: number) => {
        if(inputGroups.length > 0) {
            setInputGroups([]);
        }

        Axios.get(`${apiUrl}/v1/datamod`,
        {
            params: {
                base_data_id: base_data_id,
                document_id: document_id??'',
            },
            headers: { 'Authorization': `Bearer ${authToken}` },
        }
        ).then(res => {
            var data = res.data.data;

            Object.keys(data).forEach((key: string) => {
                let inputs: Array<InputProps> = [];
                let inputBox: Array<InputProps> = [];
                let inputBoxes: any = [];

                data[key].fields.forEach((_field: any, _fieldKey: any) => {
                    if(!data[key].repeat) {
                        let input: InputProps = {
                            modelType: _field.model_type,
                            modelId: _field.model_id,
                            type: _field.type,
                            key: _field.key,
                            value: (_field.datamod_value !== null ? _field.datamod_value : _field.value),
                            label: _field.label,
                            oldValue: (_field.datamod_value !== null ? _field.datamod_value : _field.value),
                            datamodValue: _field.datamod_value,
                            edit: _field.edit,
                            hasChanged: false,
                        };
                        inputs.push(input);
                    } else {
                        if(_field.items !== undefined && _field.items.length > 0) {
                            inputBox = [];
                            _field.items.forEach((_inner: any, _innerKey: any) => {
                                let input: InputProps = {
                                    modelType: _inner.model_type,
                                    modelId: _inner.model_id,
                                    type: _inner.type,
                                    key: _inner.key,
                                    value: (_inner.datamod_value !== null ? _inner.datamod_value : _inner.value),
                                    label: _inner.label,
                                    oldValue: (_inner.datamod_value !== null ? _inner.datamod_value : _inner.value),
                                    datamodValue: _inner.datamod_value,
                                    edit: _inner.edit,
                                    hasChanged: false,
                                };
                                inputBox.push(input);
                            });

                            inputBoxes.push({
                                'label': _field.label,
                                'type': _field.type,
                                'items': inputBox,
                                'showModal': false,
                            });
                        }
                    }
                });

                setInputGroups(prevState => [
                    ...prevState,
                    {
                        group_key: key,
                        group_name: data[key].name,
                        repeat: data[key].repeat,
                        inputs: inputs,
                        inputBoxes: (inputBoxes.length > 0 ? inputBoxes : null),
                        hasChanged: false,
                        defaultEditFields: data[key].default_edit_fields,
                        isOpen: false,
                    }
                ]);
            });

            setGettingInputs(false);
        }).catch(err => {
            setGettingInputs(false);
            checkAxiosError(err);
        });
    }

    const handleInputChange = (event: any, inputGroupKey: any, inputKey: any, inputBoxKey?: any) => {
        const values = [...inputGroups];
        
        if(inputBoxKey !== undefined && inputBoxKey !== null) {
            if(values[inputGroupKey].inputBoxes[inputBoxKey]['items'][inputKey].value !== event.target.value) {
                values[inputGroupKey].inputBoxes[inputBoxKey]['items'][inputKey].value = event.target.value;
                values[inputGroupKey].hasChanged = true;
                values[inputGroupKey].inputBoxes[inputBoxKey]['items'][inputKey].hasChanged = true;
            }
        } else {
            if(values[inputGroupKey].inputs[inputKey].value !== event.target.value) {
                values[inputGroupKey].inputs[inputKey].value = event.target.value;
                values[inputGroupKey].hasChanged = true;
                values[inputGroupKey].inputs[inputKey].hasChanged = true;
            }
        }

        if(!hasUpdated) {
            setHasUpdated(true);
        }

        setInputGroups(values);
    }

    const handleNewElementButton = (inputGroupKey: any) => {
        if(inputGroups[inputGroupKey].defaultEditFields !== null) {
            const values = [...inputGroups];

            let inputs: Array<InputProps> = [];
            inputGroups[inputGroupKey].defaultEditFields.forEach((_field: any, _fieldKey: any) => {
                let input: InputProps = {
                    modelType: _field.model_type,
                    modelId: _field.model_id,
                    type: _field.type,
                    key: _field.key,
                    value: "",
                    label: _field.label,
                    oldValue: "",
                    datamodValue: null,
                    edit: _field.edit,
                    hasChanged: false,
                    newlyAdded: true,
                };
                inputs.push(input);
            });

            values[inputGroupKey].inputBoxes.push({
                'label': 'Új elem',
                'type': 'none',
                'items': inputs,
            });
            setInputGroups(values);
        }
    }

    const setShowModal = (inputGroupKey: any, inputBoxKey: any, open: boolean) => {
        const values = [...inputGroups];
        values[inputGroupKey]['inputBoxes'][inputBoxKey].showModal = open;
        setInputGroups(values);
    }

    const setOpenCloseInputGroup = (inputGroupKey: any) => {
        if(inputGroupKey !== undefined && inputGroupKey !== null) {
            const values = [...inputGroups];
            values[inputGroupKey].isOpen = !values[inputGroupKey].isOpen;
            setInputGroups(values);
        }
    }

    const saveUpdatedInputs = () => {
        inputGroups.forEach((_group: any, _groupKey: any) => {
            if(_group.hasChanged) {
                if(!_group.repeat) {
                    let bodyFormData = new FormData();
                    bodyFormData.append('base_data_id', filters[selectedFilter].base_data_id.toString());
                    bodyFormData.append('related_type', _group.inputs[0].modelType);
                    bodyFormData.append('related_id', _group.inputs[0].modelId);
                    bodyFormData.append('datamod_type', 'edit');
    
                    var i = 0;
                    _group.inputs.forEach((_input: any, _inputKey: any) => {
                        if(_input.hasChanged) {
                            bodyFormData.append('items[' + i + '][key]', _input.key);
                            bodyFormData.append('items[' + i + '][value]', _input.value);
                            i++;
                        }
                    });
    
                    setLoadingItems(prevState => prevState + 1);
                    setIsLoading(true);
                    Axios.post(`${apiUrl}/v1/datamod`,
                    bodyFormData,
                    {
                        headers: { 'Authorization': `Bearer ${authToken}` },
                    }
                    ).then(res => {
                        setLoadingItems(prevState => prevState - 1);
                    }).catch(err => {
                        checkAxiosError(err);
                    });
                } else {
                    _group.inputBoxes.forEach((_inputBox: any, _inputBoxKey: any) => {
                        let bodyFormData = new FormData();
                        bodyFormData.append('base_data_id', filters[selectedFilter].base_data_id.toString());
                        bodyFormData.append('related_type', _inputBox.items[0].modelType);

                        if(_inputBox.items[0].newlyAdded !== undefined && _inputBox.items[0].newlyAdded === true) {
                            bodyFormData.append('datamod_type', 'new');
                        } else {
                            bodyFormData.append('datamod_type', 'edit');
                            bodyFormData.append('related_id', _inputBox.items[0].modelId);
                        }

                        var i = 0;
                        _inputBox.items.forEach((_input: any, _inputKey: any) => {
                            if(_input.hasChanged) {
                                bodyFormData.append('items[' + i + '][key]', _input.key);
                                bodyFormData.append('items[' + i + '][value]', _input.value);
                                i++;
                            }
                        });

                        // Ha van módosult elem csak akkor küldjük be!
                        if(i !== 0) {
                            setLoadingItems(prevState => prevState + 1);
                            setIsLoading(true);
                            Axios.post(`${apiUrl}/v1/datamod`,
                            bodyFormData,
                            {
                                headers: { 'Authorization': `Bearer ${authToken}` },
                            }
                            ).then(res => {
                                setLoadingItems(prevState => prevState - 1);
                            }).catch(err => {
                                checkAxiosError(err);
                            });
                        }
                    })
                }
            }
        });
    }
    /* DatamodInputs - End */

    const getIconByType = (type: string) => {
        switch (type) {
            case 'LEV':
                return (
                    <IonIcon icon={mailOpenOutline} slot="start" />
                );
            case 'SZAM':
                return (
                    <IonIcon icon={walletOutline} slot="start" />
                );
            case 'BIZ':
            case 'EGY':
            case 'TAV':
                return (
                    <IonIcon icon={homeOutline} slot="start" />
                );
            case 'none':
            default:
                return (
                    <></>
                );
        }
    }

    const getTitleByType = (type: string, originalTitle: string) => {
        switch (type) {
            case 'LEV':
                return (
                    <>
                        Levelezési
                    </>
                );
            case 'SZAM':
                return (
                    <>
                        Számlázási
                    </>
                );
            case 'BIZ':
                return (
                    <>
                        Biztonságtechnikai
                    </>
                );
            case 'EGY':
                return (
                    <>
                        Egyéb
                    </>
                );
            case 'TAV':
                return (
                    <>
                        Távfelügyelet
                    </>
                );
            case 'none':
            default:
                return (
                    <>
                        {originalTitle??''}
                    </>
                );
        }
    }

    const resetDatamods = () => {
        setShowPopover({open: false, event: undefined});
        setResetLoading(true);
        Axios.get(`${apiUrl}/v1/datamod-reset`,
        {
            headers: { 'Authorization': `Bearer ${authToken}` },
        }
        ).then(res => {
            setResetLoading(false);
            setShowResetDatamodsDoneAlert('Sikeres törlés!');
            pullToRefresh(null);
        }).catch(err => {
            setResetLoading(false);
            checkAxiosError(err);
            setShowResetDatamodsDoneAlert('Sikertelen törlés!');
        });
    }

    useIonViewDidEnter(() => {
        setUpdateCompleted(false);
        pullToRefresh(null);
    });

    /*useEffect(() => {
        setUpdateCompleted(false);
        getFilters();
    }, []);*/

    useEffect(() => {
        if(filters.length > 0 && !gettingInputs) {
            getDatamodInputs(filters[selectedFilter].base_data_id, filters[selectedFilter].document_id);
            setGettingInputs(true);
            setHasUpdated(false);
        }
    }, [selectedFilter, filters]);

    useEffect(() => {
        if(isLoading && loadingItems === 0) {
            setUpdateCompleted(true);
            setHasUpdated(false);
            setIsLoading(false);
            setFilters([]);
            setSelectedFilter(0);
            setInputGroups([]);
            getFilters();
        }
    }, [loadingItems, isLoading]);

    useEffect(() => {
        if(noDataMod) {
            pullToRefresh(null);
        }
    }, [noDataMod]);

    const pullToRefresh = (event?: CustomEvent<RefresherEventDetail>|null) => {
        if(event !== null) {
            event.detail.complete();
        }
        setSelectedFilter(0);
        setFilters([]);
        setHasUpdated(false);
        setIsLoading(false);
        setInputGroups([]);
        getFilters();
    }

    const canGroupHasANewItemButton = (group: any) => {
        if(group.group_key === 'base_data_related_items') {
            return false;
        }

        return true;
    }

    const clickBackspaceButton = (input: any, inputGroupKey: any, inputKey: any, inputBoxKey?: any) => {
        const values = [...inputGroups];
        
        if(inputBoxKey !== undefined && inputBoxKey !== null) {
            values[inputGroupKey].inputBoxes[inputBoxKey]['items'][inputKey].value = values[inputGroupKey].inputBoxes[inputBoxKey]['items'][inputKey].oldValue;
            values[inputGroupKey].inputBoxes[inputBoxKey]['items'][inputKey].hasChanged = false;
        } else {
            values[inputGroupKey].inputs[inputKey].value = values[inputGroupKey].inputs[inputKey].oldValue;
            values[inputGroupKey].inputs[inputKey].hasChanged = false;
        }

        setInputGroups(values);
    }

    const getMypatentInput = (input: any, inputGroupkey: any, inputKey: any, inputBoxKey?: any) => {
        if(typeof input.type === 'object' && input.type !== null) {
            return (
                <IonSelect 
                    cancelText="Mégse" 
                    interface="action-sheet" 
                    value={input.value} 
                    placeholder="Kérem válasszon" 
                    disabled={!input.edit}
                    onIonChange={(event: any) => handleInputChange(event, inputGroupkey, inputKey, inputBoxKey)}
                    >
                        {
                            Object.keys(input.type).map((key: any, index: any) => 
                                <IonSelectOption key={index} value={key}>{input.type[key]}</IonSelectOption>
                            )
                        }
                </IonSelect>
            );
        } else {
            switch (input.type) {
                case 'bool':
                    return (
                        <IonToggle 
                            color="primary"
                            value={input.value ? 'on' : 'off'}
                            checked={input.value ? true : false}
                            onIonChange={(event: any) => handleInputChange(event, inputGroupkey, inputKey, inputBoxKey)}
                            disabled={!input.edit}
                            />
                    );

                case 'date':
                case 'datetime':
                    return (
                        <IonDatetime
                            value={input.value}
                            onIonChange={(event: any) => handleInputChange(event, inputGroupkey, inputKey, inputBoxKey)}
                            doneText="Kész"
                            cancelText="Mégse"
                            disabled={!input.edit}
                            displayFormat="YYYY. MM. DD."
                            pickerFormat="YYYY. MM. DD."
                            placeholder="Kérem válasszon" 
                            monthShortNames={[
                                "Jan",
                                "Feb",
                                "Már",
                                "Ápr",
                                "Máj",
                                "Jún",
                                "Júl",
                                "Aug",
                                "Szep",
                                "Okt",
                                "Nov",
                                "Dec",
                            ]}
                        />
                    );
                    
                case 'number':
                case 'email':
                case 'phone':
                case 'text':
                default:
                    return (
                        <IonInput 
                            disabled={!input.edit} 
                            type={input.type??'text'}
                            value={input.value} 
                            placeholder="Nincs érték" 
                            onIonInput={(event: any) => handleInputChange(event, inputGroupkey, inputKey, inputBoxKey)} 
                        />
                    );
            }
        }
    }

    if(noDataMod) {
        return (
            <PageWrapper>
                <IonRefresher slot="fixed" onIonRefresh={pullToRefresh}>
                    <IonRefresherContent
                        pullingIcon={caretDown}
                        pullingText=""
                        refreshingSpinner="crescent"
                        refreshingText="">
                    </IonRefresherContent>
                </IonRefresher>
                
                <div className="hasButton">
                    <h1 className="pageTitle">Adataim</h1>
                    <IonButton size="small" className="hideOnMobile" color="success" onClick={() => pullToRefresh(null)}>Frissítés</IonButton>
                </div>

                <EmptyText text="Nincs módosítható adat" subtext="Nem található adat amely módosítható lenne, kérem keresse fel ügyintézőnket a hibával kapcsolatban." />
            </PageWrapper>
        )
    }

    if(updateCompleted !== null && updateCompleted === true) {
        return <Redirect to="/my/profile-complete" />;
    }

    return (
        <PageWrapper>
            <IonRefresher slot="fixed" onIonRefresh={pullToRefresh}>
                <IonRefresherContent
                    pullingIcon={caretDown}
                    pullingText=""
                    refreshingSpinner="crescent"
                    refreshingText="">
                </IonRefresherContent>
            </IonRefresher>
            
            <div className="hasButton">
                <h1 className="pageTitle">Adataim</h1>
                <IonButton size="small" className="hideOnMobile" color="success" onClick={() => pullToRefresh(null)}>Frissítés</IonButton>

                <IonButton fill="clear" className="moreButton" onClick={(e) => setShowPopover({open: true, event: e.nativeEvent})}>
                    <IonIcon slot="icon-only" icon={ellipsisVertical} />
                </IonButton>
                <IonPopover
                        isOpen={showPopover.open}
                        event={showPopover.event}
                        onDidDismiss={e => setShowPopover({open: false, event: undefined})}
                    >
                        <IonList>
                            <IonItem button={true} detail={true} lines="none" onClick={() => setShowResetDatamodsAlert(true)}>
                                Meglévő adatmódosítások törlése
                            </IonItem>
                        </IonList>
                </IonPopover>
            </div>
            
            <div className="inputWithModalWrapper">
                <IonItem className="inputWithModal highlightedSelect" lines="none">
                    {filters.length > 0 
                    ?
                        <IonSelect cancelText="Mégse" interface="action-sheet" className="fullLength" value={selectedFilter} placeholder="Kérem válasszon" disabled={disableFilter} onIonChange={(e) => setSelectedFilterWithAlert(e.detail.value)}>
                            {
                                filters.map((filter, index) => 
                                    <IonSelectOption key={index} value={index}>{filter.label}</IonSelectOption>
                                )
                            }
                        </IonSelect>
                    :
                        <IonLabel color="secondary">Betöltés...</IonLabel>
                    }
                </IonItem>
            </div>
            <br/>

            {(inputGroups.length > 0)
            ?
                <>
                    {inputGroups
                        .filter((group) => group.group_name !== '')
                        .map((inputGroup: any, inputGroupkey: any) => (
                            <IonCard className="datamodList ion-margin-bottom" key={inputGroupkey}>
                                <IonItemGroup>
                                    <IonCardHeader onClick={() => setOpenCloseInputGroup(inputGroupkey)}>
                                        <IonCardTitle>
                                            {inputGroup.group_name??''} 
                                            <IonIcon icon={chevronDownOutline} size="small" className={"chevronIcon " + (inputGroup.isOpen ? "isOpen" : "isClosed")} />
                                        </IonCardTitle>
                                    </IonCardHeader>

                                    <IonCardContent className={inputGroup.isOpen ? "isOpen" : "isClosed"}>
                                        {(inputGroup.inputs !== null && inputGroup.inputs.length > 0) 
                                        ?
                                            <>
                                                {inputGroup.inputs.map((input: any, inputKey: any) => (
                                                    <div key={inputKey}>
                                                        <IonItem className={input.hasChanged ? 'hasChanged' : ''}>
                                                            <IonLabel position="floating">{input.label}</IonLabel>
                                                            {getMypatentInput(input, inputGroupkey, inputKey)}

                                                            {input.hasChanged 
                                                            ?
                                                                <IonButton color="warning" slot="end" className="backspaceButton" onClick={() => clickBackspaceButton(input, inputGroupkey, inputKey)}>
                                                                    <IonIcon icon={backspace} slot="icon-only" />
                                                                </IonButton>
                                                            :
                                                                <>
                                                                    {input.edit &&
                                                                        <IonIcon className="backspaceButton" icon={pencilSharp} slot="end" />
                                                                    }
                                                                </>
                                                            }
                                                        </IonItem>

                                                        {input.hasChanged 
                                                        ?
                                                            <IonItem className="smallItem" lines="none">
                                                                <IonBadge color="warning">Az elem értéke megváltozott, mentésre vár.</IonBadge >
                                                            </IonItem>
                                                        :    
                                                            <>
                                                                {input.datamodValue !== null &&
                                                                    <IonItem className="smallItem" lines="none">
                                                                        <IonBadge color="success">Adatmódosítás folyamatban</IonBadge>
                                                                    </IonItem>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                ))}
                                            </>
                                        :
                                            <>
                                                {(inputGroup.inputBoxes !== null && inputGroup.inputBoxes.length > 0) &&
                                                    <>
                                                        {inputGroup.inputBoxes.map((inputBox: any, inputBoxKey: any) => (
                                                            <IonItem key={inputBoxKey} button={true} onClick={() => {setShowModal(inputGroupkey, inputBoxKey, true)}}>
                                                                {getIconByType(inputBox.type)}
                                                                <IonLabel>
                                                                    {inputBox.label??''}
                                                                </IonLabel>
                                                            </IonItem>
                                                        ))}

                                                        {inputGroup.inputBoxes.map((inputBox: any, inputBoxKey: any) => (
                                                            <IonModal backdropDismiss={false} keyboardClose={false} swipeToClose={false} isOpen={inputBox.showModal} key={inputBoxKey}>
                                                                <IonHeader translucent>
                                                                    <IonToolbar>
                                                                        <IonTitle>
                                                                            {getTitleByType(inputBox.type, inputGroup.group_name)}
                                                                        </IonTitle>
                                                                        <IonButtons slot="end">
                                                                            <IonButton onClick={() => setShowModal(inputGroupkey, inputBoxKey, false)}>Rendben</IonButton>
                                                                        </IonButtons>
                                                                    </IonToolbar>
                                                                </IonHeader>
                                                                <IonContent fullscreen>

                                                                    {inputBox.items.map((input: any, inputKey: any) => (
                                                                        <div key={inputKey} className="datamodListModal">
                                                                            <IonItem className={input.hasChanged ? 'hasChanged' : ''}>
                                                                                <IonLabel position="floating">{input.label}</IonLabel>
                                                                                {getMypatentInput(input, inputGroupkey, inputKey, inputBoxKey)}

                                                                                {input.hasChanged 
                                                                                ?
                                                                                    <IonButton color="warning" slot="end" className="backspaceButton" onClick={() => clickBackspaceButton(input, inputGroupkey, inputKey, inputBoxKey)}>
                                                                                        <IonIcon icon={backspace} slot="icon-only" />
                                                                                    </IonButton>
                                                                                :
                                                                                    <>
                                                                                        {input.edit &&
                                                                                            <IonIcon className="backspaceButton" icon={pencilSharp} slot="end" />
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </IonItem>

                                                                            {input.hasChanged 
                                                                            ?
                                                                                <IonItem className="smallItem" lines="none">
                                                                                    <IonBadge color="warning">Az elem értéke megváltozott, mentésre vár.</IonBadge >
                                                                                </IonItem>
                                                                            :    
                                                                                <>
                                                                                    {input.datamodValue !== null &&
                                                                                        <IonItem className="smallItem" lines="none">
                                                                                            <IonBadge color="success">Adatmódosítás folyamatban</IonBadge>
                                                                                        </IonItem>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    ))}
                                                                    <br/>
                                                                    <div className="ion-padding">
                                                                        <IonButton expand="block" color="success" onClick={() => setShowModal(inputGroupkey, inputBoxKey, false)}>Rendben</IonButton>
                                                                    </div>
                                                                </IonContent>
                                                            </IonModal>
                                                        ))}

                                                        {canGroupHasANewItemButton(inputGroup) &&
                                                            <div className="paddingForButton">
                                                                <IonButton color="success" expand="block" onClick={() => handleNewElementButton(inputGroupkey)}>
                                                                    Új hozzáadása
                                                                </IonButton>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </IonCardContent>
                                </IonItemGroup>
                            </IonCard>
                        ))
                    }
                </>
            :
                <>
                    <SkeletonProfileItem count={10} />
                </>
            }

            {hasUpdated &&
                <>
                    <br/><br/><br/>
                    <div className="stickyButton">
                        <IonButton expand="block" color="success" className="saveButton" onClick={() => saveUpdatedInputs()}>
                            Adatmódosítási kérelem mentése
                        </IonButton>
                    </div>
                </>
            }

            {(isLoading && loadingItems !== 0) &&
                <IonLoading
                    isOpen={isLoading}
                    message={'Adatmódosítási kérelem beküldése...'}
                />
            }

            <IonAlert 
                isOpen={updateCompleted}
                onDidDismiss={() => setUpdateCompleted(false)}
                header={'Siker'}
                message={'Az adamódosítási kérelem beküldésre került, hamarosan kollégánk foglalkozni fog vele. A folyamat kezdetéig az adatok még módosíthatók.'}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
            />

            
            {(alertSelectedFilter !== null) &&
                <IonAlert
                    isOpen={Boolean(alertSelectedFilter !== null)}
                    onDidDismiss={() => {
                        setAlertSelectedFilter(null);
                    }}
                    header="Figyelem"
                    message="A beírt értékek elvesznek, biztosan folytatja?"
                    buttons={[
                        {
                            text: 'Igen',
                            handler: () => {
                                setSelectedFilter(alertSelectedFilter);
                                setAlertSelectedFilter(null);
                            }
                        },
                        {
                            text: 'Nem',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                setAlertSelectedFilter(null);
                            }
                        }
                    ]}
                />
            }

            <IonAlert
                isOpen={showResetDatamodsAlert}
                onDidDismiss={() => {
                    setShowResetDatamodsAlert(false);
                }}
                header="Figyelem"
                message="Biztos törölni szeretné az összes adatmódosítási kérelmét?"
                buttons={[
                    {
                        text: 'Igen',
                        handler: () => {
                            resetDatamods();
                            setShowResetDatamodsAlert(false);
                        }
                    },
                    {
                        text: 'Nem',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowResetDatamodsAlert(false);
                        }
                    }
                ]}
            />

            <IonAlert 
                isOpen={Boolean(showResetDatamodsDoneAlert !== null)}
                onDidDismiss={() => setShowResetDatamodsDoneAlert(null)}
                header={'Információ'}
                message={showResetDatamodsDoneAlert}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
            />

            {resetLoading &&
                <IonLoading
                    isOpen={resetLoading}
                    message={'Kérem várjon...'}
                />
            }
        </PageWrapper>
    );
};

export default DatamodPage;

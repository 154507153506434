import React from 'react';
import { IonText } from '@ionic/react';

export const isObject = (data: object) => {
    return (!!data) && (data.constructor === Object);
}

export const forEachApiErrors = (err: any, setState: any) => {
    Object.keys(err).forEach((key) => {
        setState((prevState: any) => ({
            ...prevState,
            error_list: {
                ...prevState.error_list,
                [key]: err[key],
            },
        }));
    });
}

export const addApiErrors = (err: any, setState: any) => {
    if(isObject(err)) {
        forEachApiErrors(err, setState);
    } else {
        setState((prevState: any) => ({
            ...prevState,
            error_list: {
                ...prevState.error_list,
                'error': err,
            },
        }));
    }
}

export const setLoading = (loading: boolean, setState: any) => {
    setState((prevState: any) => ({
        ...prevState,
        loading: loading,
    }));
}

export const setInitError = (init_state: any, setState: any) => {
    setState((prevState: any) => ({
        ...prevState,
        error_list: init_state,
    }));
}

export const hasError = (err: any) => {
    return (Object.keys(err).length > 0);
}

export const ErrorsList: React.FC<{ errors: any }> = ({ errors }) => {
    return (
        <div className="errorsList">
            {
                Object.entries(errors).map(([key, value], index) => {
                        if(value) {
                            return (
                                <p className="ion-text-center" data-testid="errorText" key={index}>
                                    <IonText color="danger">{value ?? ''}</IonText>
                                </p>
                            )
                        } else {
                            return <div key={index}></div>;
                        }
                    }
                )
            }
        </div>
    )
}

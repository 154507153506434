import React, { useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonAlert, IonLoading, IonIcon } from '@ionic/react';

import useFormValidation from '../../helpers/FormValidationHelper';
import ValidateUgyfelszam from '../../helpers/validators/ValidateUgyfelszam';
import { useAuth } from '../../context/Auth';
import Axios from 'axios';
import { setLoading, setInitError, hasError, addApiErrors, ErrorsList } from '../../helpers/GlobalHelpers';
import { callOutline, mailOutline } from 'ionicons/icons';
import { isMobileNumber } from '../../helpers/MobilePhoneHelper';
import { useUrl } from '../../context/Url';

const INIT_STATE = {
    ugyfelszam: '',
};

interface Props {
    setCurrentPage: (num: number) => void;
    setRelation: (data: any) => void;
}

const AssignWelcomePage: React.FC<Props> = ({ setCurrentPage, setRelation }) => {
    const { authToken, assigns, setLogout, checkAxiosError } = useAuth();
    const { handleChange, values } = useFormValidation(INIT_STATE);
    const { apiUrl } = useUrl();
    const [status, setStatus] = useState({
        loading: false,
        error_list: INIT_STATE,
    });

    const [showUgyfelszamAlert, setShowUgyfelszamAlert] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === 'Enter') {
            handleSendUgyfelszam();
        }
    }

    const handleSendUgyfelszam = async () => {
        setLoading(true, setStatus);
        setInitError(INIT_STATE, setStatus);

        const errors = ValidateUgyfelszam(values);
        if (hasError(errors)) {
            setLoading(false, setStatus);
            addApiErrors(errors, setStatus);
        } else {
            Axios.post(`${apiUrl}/v1/assign`, {
                customer_code: values.ugyfelszam,
            },
            {
                headers: {'Authorization': `Bearer ${authToken}`},
            }).then(res => {
                setLoading(false, setStatus);

                setRelation(res.data.data);

                if(res.data.wait_to_verify) {
                    setCurrentPage(3);
                } else {
                    if(res.data.has_phone) {
                        if(isMobileNumber(res.data.data.verify_phone_number)) {
                            setCurrentPage(2);
                        } else {
                            setCurrentPage(1);
                        }
                    } else {
                        setCurrentPage(1);
                    }
                }
            }).catch(err => {
                checkAxiosError(err);
                setLoading(false, setStatus);

                if(!err.response) {
                    addApiErrors('Hálózati hiba.', setStatus);
                } else {
                    addApiErrors(err.response.data.message, setStatus);
                }

                setShowUgyfelszamAlert(true);
            });
        }
    }

    return (
        <>
            <h4>Üdvözöljük!</h4>
            <p>
                Hogy használni tudja szolgáltatásunkat, kérjük rendelje magához szerződéseit.
                <br /><br />
                Ehhez adja meg az ügyfél azonosítóját, melyet megtalál a számla vagy szerződése fejlécében <b>Partner azonosító</b> néven.
            </p>

            <div className="form">
                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.ugyfelszam ? 'error' : '')}>
                    <IonLabel position="floating">Partner azonosító</IonLabel>
                    <IonInput onKeyDown={handleKeyDown} clearOnEdit={false} type="text" inputmode="text" name="ugyfelszam" onIonChange={handleChange} value={values.ugyfelszam} required autofocus={true}></IonInput>
                </IonItem>
            </div>
            
            <ErrorsList errors={status.error_list} />

            <IonButton color="success" expand="block" onClick={handleSendUgyfelszam}>Hozzám rendelés</IonButton>

            {assigns.length === 0 
            ? <IonButton color="medium" expand="block" onClick={setLogout}>Kijelentkezés</IonButton>
            : <IonButton color="medium" expand="block" routerLink="/my/dashboard" routerDirection="none">Vissza a főoldalra</IonButton>
            }

            <br/>
            <p>
                Ha problémát észlelt kérjük keresse fel ügyintézőnket az alábbi elérhetőségeken.
            </p>
            <IonButton color="medium" expand="block" href="mailto:info@patentcsoport.hu" target="_blank">
                <IonIcon slot="start" icon={mailOutline} />
                info@patentcsoport.hu
            </IonButton>
            <IonButton color="medium" expand="block" href="tel:+36305060111" target="_blank">
                <IonIcon slot="start" icon={callOutline} />
                +36 30 50 60 111
            </IonButton>
            
            <IonLoading
                isOpen={status.loading}
                message={'Kérem várjon...'}
            />

            <IonAlert
                isOpen={showUgyfelszamAlert}
                onDidDismiss={() => setShowUgyfelszamAlert(false)}
                header={'Hiba'}
                message={'A beírt kód alapján rendszerünk nem talált profilt, vagy a beírt Partner azonosítót már hozzárendelték egy myPatent felhasználóhoz.'}
                buttons={[
                    {
                        text: 'Újra próbálom',
                        role: 'cancel',
                    }
                ]}
            />
        </>
    );
};

export default AssignWelcomePage;

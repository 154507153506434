import { IonItem, IonItemDivider, IonLabel, IonSelect, IonSelectOption, IonToggle } from '@ionic/react';
import React from 'react';

export enum FilterTypes {
    Select,
    Checkbox
}

export interface FilterInput {
    key: string;
    value: string;
}

export interface FilterObject {
    key: string;
    label: string;
    type: FilterTypes;
    inputs: FilterInput[];
    value: string;
    default: string;
}

export interface FilterJsonObject {
    key: string;
    value: string;
}

export interface FilterQueryObject {
    skip: number;
    filters: FilterJsonObject[]|null;
}

export const getFiltersArray = (filters: any) => {
    var _filters: FilterObject[] = [];

    if(filters && filters.length > 0) {
        filters.forEach((_fil: any) => {
            var _inputs: FilterInput[] = [];
            Object.entries(_fil.values).forEach((item: any) => {
                var _inp: FilterInput = {
                    key: item[0],
                    value: item[1],
                };
                _inputs.push(_inp);
            });
    
            var _type = FilterTypes.Select;
            switch (_fil.input) {
                case 'checkbox':
                    _type = FilterTypes.Checkbox;
                    break;
            
                case 'select':
                default:
                    _type = FilterTypes.Select;
                    break;
            }
    
            var _filter: FilterObject = {
                key: _fil.key,
                label: _fil.label,
                type: _type,
                inputs: _inputs,
                value: _inputs[0].key,
                default: _inputs[0].key,
            };
            _filters.push(_filter);
        });         
    }

    return _filters;
};

export const Filter: React.FC<{ filter: FilterObject, setFilterValue: (name: string, value: string) => void }> = ({ filter, setFilterValue }) => {
    switch (filter.type) {
        default:
        case FilterTypes.Select:
            return (
                <FilterSelect name={filter.key} value={filter.value} inputs={filter.inputs} label={filter.label} setFilterValue={(name, value) => setFilterValue(name, value)} />
            );
    
        case FilterTypes.Checkbox:
            return (
                <FilterCheckbox inputs={filter.inputs} label={filter.label} />
            );
    }
}

const FilterCheckbox: React.FC<{ label: string, inputs: FilterInput[] }> = ({ label, inputs }) => {
    return (
        <>
            <IonItemDivider>{label}</IonItemDivider>
            {inputs.map((input, key) => (
                <IonItem key={key} lines="none">
                    <IonLabel>{input.value}</IonLabel>
                    <IonToggle color="success" checked={true} name={input.key} />
                </IonItem>
            ))}
        </>
    );
}

const FilterSelect: React.FC<{ name: string, value: string, label: string, inputs: FilterInput[], setFilterValue: (name: string, value: string) => void, }> = ({ name, value, label, inputs, setFilterValue }) => {
    return (
        <>
            <IonItemDivider>{label}</IonItemDivider>
            <IonItem lines="none">
                <IonLabel>{label}</IonLabel>
                <IonSelect 
                    value={value} 
                    onIonChange={e => setFilterValue(name, e.detail.value)} 
                    className="filterSelect"
                    cancelText="Mégse"
                    okText="Kiválasztás"
                >
                    {inputs.map((input, key) => (
                        <IonSelectOption key={key} value={input.key}>{input.value}</IonSelectOption>
                    ))}
                </IonSelect>
            </IonItem>
        </>
    );
}

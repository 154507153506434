import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { IonApp, IonLoading, IonToast } from '@ionic/react';
import { IonReactRouter } from "@ionic/react-router";

/* Context */
import { AuthContext } from "./context/Auth";
import { UrlContext } from "./context/Url";

import AppTabs from './AppTabs';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import NotFoundPage from './pages/NotFoundPage';
import AssignPage from './pages/AssignPage';

import './App.scss';
import LostPasswordPage from './pages/LostPasswordPage';
import PasswordRecoveryPage from './pages/PasswordRecoveryPage';
import Axios from 'axios';
import RegistrationVerifyPage from './pages/RegistrationVerifyPage';

import { Plugins, Capacitor } from '@capacitor/core';
const { PushNotifications } = Plugins;

const App: React.FC = () => {
    const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
    const [authToken, setAuthToken] = useState('');
    const [assigns, setAssign] = useState([]);
    const [assignsLoaded, setAssignLoaded] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [logoutLoader, setLogoutLoader] = useState<boolean>(false);
    const [isOffline, setIsOffline] = useState<boolean>(false);

    const [ apiUrl, setApiUrl ] = useState<string>(
        (localStorage.getItem('isDev') !== undefined && localStorage.getItem('isDev') === 'true') ? process.env.REACT_APP_MYPATENT_DEV_API_URL : process.env.REACT_APP_MYPATENT_API_URL
    );
    const [ fileUrl, setFileUrl ] = useState<string>(
        (localStorage.getItem('isDev') !== undefined && localStorage.getItem('isDev') === 'true') ? process.env.REACT_APP_MYPATENT_DEV_FILE_URL : process.env.REACT_APP_MYPATENT_FILE_URL
    );
    const [ isDev, setIsDev ] = useState<boolean>(
        (localStorage.getItem('isDev') !== undefined && localStorage.getItem('isDev') === 'true') ? true : false
    );

    const setAuthStatus = (login: boolean, token: string) => {
        if (login) {
            setUserInfo(null);
            setAssignLoaded(false);
            setAssigns('empty');
            Axios.get(`${apiUrl}/v1/user`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then(res => {
                setUserInfo(res.data.data);

                Axios.get(`${apiUrl}/v1/get-relations`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }).then(res => {
                    if (Object.keys(res.data.data).length > 0) {
                        setAssigns(res.data.data);
                    }

                    setAssignLoaded(true);
                }).catch(err => {
                    checkAxiosError(err);
                });

            }).catch(err => {
                checkAxiosError(err);
            });
        }

        setLoggedIn(login);

        setAuthToken(token);
        if (localStorage.getItem('authToken') !== token) {
            localStorage.setItem('authToken', token);
        }
    }

    useEffect(() => {
        setAuthStatus(
            Boolean(localStorage.getItem('authToken')),
            localStorage.getItem('authToken')
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    window.addEventListener('offline', () => {
        if(isOffline === false) {
            setIsOffline(true);
        }
    });

    window.addEventListener('online', () => {
        if(isOffline === true) {
            setIsOffline(false);
        }
    });

    const checkAxiosError = (error: any) => {
        if (!error.response) {
            setErrorMsg('Hiba történt!');
        }

        if(error.response !== undefined && error.response.status === 401) {
            setLogout();
        }
    }

    const setLogout = () => {
        setLogoutLoader(true);
        Axios.get(`${apiUrl}/v1/logout`,
            {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
            }).then(res => {
                emptyLocalItems();
                setLogoutLoader(false);
            }).catch(err => {
                emptyLocalItems();
                setLogoutLoader(false);
            });
    }

    const emptyLocalItems = () => {
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            Axios
                .post(`${apiUrl}/v1/push-token-delete`, {
                    token: localStorage.getItem('pushToken'),
                }, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                })
                .catch(err => {
                    console.error(err);
                });

            PushNotifications.removeAllListeners();
        } else {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }
            });
        }

        localStorage.setItem('pushToken', '');
        setAuthStatus(false, '');
        setAssigns('empty');
        setUserInfo(null);
    }

    const setAssigns = (assign: any) => {
        if (assign === 'empty') {
            setAssign([]);
        } else {
            setAssign((prevState) => [
                ...prevState,
                assign,
            ]);
        }
    }

    const [lastBackButtonTime, _setLastBackButtonTime] = useState<number>(new Date().getTime());
    const [showLastBackToast, setShowLastBackToast] = useState<boolean>(false);
    const lastBackButtonTimeRef = React.useRef(lastBackButtonTime);
    const setLastBackButtonTime = (data: any) => {
        lastBackButtonTimeRef.current = data;
        _setLastBackButtonTime(data);
    };

    useEffect(() => {
        if (Capacitor.isNative) {
            Plugins.App.addListener("backButton", (e) => {
                if (
                    window.location.pathname === "/my/dashboard" ||
                    window.location.pathname === "/login"
                ) {
                    const now = new Date().getTime();
                    if (now - lastBackButtonTimeRef.current < 3000) {
                        Plugins.App.exitApp();
                    } else {
                        setShowLastBackToast(true);
                    }

                    setLastBackButtonTime(new Date().getTime());
                } else {
                    const backdrops = document.getElementsByTagName("ion-backdrop");
                    if (backdrops && backdrops.length) {
                        backdrops[0].click();
                    } else {
                        console.log('History');
                        window.history.back();
                    }
                }
            });
        }
    }, []);


    const setDevOrProd = () => {
        setIsDev(prevState => !prevState);
    }

    useEffect(() => {
        if (localStorage.getItem('isDev') === undefined || localStorage.getItem('isDev') !== isDev.toString()) {
            localStorage.setItem('isDev', isDev.toString());
        }

        if(isDev) {
            setApiUrl(process.env.REACT_APP_MYPATENT_DEV_API_URL );
            setFileUrl(process.env.REACT_APP_MYPATENT_DEV_FILE_URL);
            setIsDev(true);
        } else {
            setApiUrl(process.env.REACT_APP_MYPATENT_API_URL);
            setFileUrl(process.env.REACT_APP_MYPATENT_FILE_URL);
            setIsDev(false);
        }
    }, [isDev]);

    return (
        <IonApp id="main">
            <UrlContext.Provider value={{ apiUrl, setApiUrl, fileUrl, setFileUrl, isDev, setDevOrProd }}>
                <AuthContext.Provider value={{ loggedIn, authToken, checkAxiosError, setLogout, assigns, setAssigns, assignsLoaded, userInfo, setUserInfo }}>
                    <IonReactRouter>
                        <Switch>
                            <Route exact path="/login">
                                <LoginPage onLogin={(login, token) => setAuthStatus(login, token)} />
                            </Route>
                            <Route exact path="/registration">
                                <RegistrationPage onRegistration={(login, token) => setAuthStatus(login, token)} />
                            </Route>
                            <Route exact path="/lost-password">
                                <LostPasswordPage />
                            </Route>
                            <Route exact path="/password-recovery/:hash/:email">
                                <PasswordRecoveryPage onLogin={(login, token) => setAuthStatus(login, token)} />
                            </Route>
                            <Route path="/my">
                                <AppTabs onLogout={() => setAuthStatus(false, '')} />
                            </Route>
                            <Route exact path="/verify">
                                <RegistrationVerifyPage />
                            </Route>
                            <Route exact path="/assign">
                                <AssignPage />
                            </Route>
                            <Redirect exact path="/" to="/my/dashboard" />
                            <Route>
                                <NotFoundPage />
                            </Route>
                        </Switch>
                    </IonReactRouter>
                </AuthContext.Provider>
            </UrlContext.Provider>

            <IonToast
                isOpen={isOffline}
                position="bottom"
                message="Nincs hálózati hozzáférés."
                color="danger"
                />

            {errorMsg &&
                <IonToast
                    isOpen={Boolean(errorMsg)}
                    onDidDismiss={() => setErrorMsg('')}
                    message={errorMsg}
                    duration={4000}
                />
            }

            {showLastBackToast &&
                <IonToast
                    isOpen={showLastBackToast}
                    onDidDismiss={() => setShowLastBackToast(false)}
                    message="Nyomja meg mégegyszer a kilépéshez"
                    duration={3000}
                />
            }

            {logoutLoader &&
                <IonLoading
                    isOpen={logoutLoader}
                    message={'Kijelentkezés...'}
                />
            }

            {isDev &&
                <div id="isDev">Fejlesztői környezet</div>
            }
        </IonApp>
    );
}

export default App;

import React, { useState, useEffect } from 'react';
import PageWrapper from '../layouts/PageWrapper';

import { useParams } from 'react-router';
import Axios from 'axios';
import { useAuth } from '../../context/Auth';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonLoading, IonText } from '@ionic/react';
import { useUrl } from '../../context/Url';

interface Documents {
    id: number;
    type: string;
    createdAt: string;
    related: any;
    title?: string;
    viewItems?: any;
    fileId?: number;
}

const DocumentsViewPage: React.FC = () => {
    const { authToken, checkAxiosError } = useAuth();
    const { apiUrl, fileUrl } = useUrl();
    const { id } = useParams();
    const [ document, setDocument ] = useState<Documents>(null);

    useEffect(() => {
        Axios.post(`${apiUrl}/v1/documents/show`,
        {
            id: id,
        },
        {
            headers: { 'Authorization': `Bearer ${authToken}` },
        }).then(res => {
            if (res.data.data) {
                setDocument({
                    id: res.data.data.id,
                    type: res.data.data.related_type,
                    createdAt: res.data.data.created_at,
                    related: res.data.data.related,
                    title: res.data.data.mypatent_title,
                    fileId: res.data.data.file_id,
                    viewItems: res.data.data.mypatent_view,
                });
            }
        }).catch(err => {
            checkAxiosError(err);
        });
    }, [])

    return (
        <PageWrapper>
            {document !== null 
            ?
                <>
                    <h1 className="pageTitle">{document.title??''}</h1>

                    {document.fileId &&
                        <IonButton href={fileUrl + "/" + document.fileId} target="_blank" color="success" expand="block">
                            PDF megnyitása
                        </IonButton>
                    }
                    <br/>

                    {(document.viewItems !== null && document.viewItems.length > 0) &&
                        <>
                            {document.viewItems.map((item: any, key: any) => (
                                <IonCard key={key} className="ion-margin-bottom">
                                    <IonCardHeader>
                                        <IonCardSubtitle>{item.label??''}</IonCardSubtitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonText color="primary">
                                            {item.value??''}
                                        </IonText>
                                    </IonCardContent>
                                </IonCard>
                            ))}
                        </>
                    }
                </>
            :
                <IonLoading
                    isOpen={Boolean(document === null)}
                    message={'Dokumentum betöltése...'}
                    />
            }

        </PageWrapper>
    );
};

export default DocumentsViewPage;

import React, { useState, useEffect } from 'react';
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed, Capacitor } from '@capacitor/core';
import { firebase } from '@firebase/app';
import '@firebase/messaging';
import { IonAlert, IonToast } from '@ionic/react';
import Axios from 'axios';
import { useNotification } from '../context/Notifications';
import { useAuth } from '../context/Auth';
import { useUrl } from '../context/Url';

const { PushNotifications, App } = Plugins;

interface NotificationTypeLocal {
    uid: string;
    title: string;
    message: string;
};

const PushNotificationHelper: React.FC = () => {
    const { apiUrl } = useUrl();
    const [showPwaNotiAlert, setShowPwaNotiAlert] = useState(false)
    const { addSingleNotification } = useNotification();
    const { authToken, checkAxiosError } = useAuth();
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            initPushApp();
        } else {
            if (("Notification" in window)) {
                if (Notification.permission !== "granted") {
                    if(localStorage.getItem('disabledDesktopNotifications') !== 'true') {
                        setShowPwaNotiAlert(true);
                    }
                } else {
                    initPushWeb();
                }
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initPushWeb = () => {
        if (Notification.permission === "granted") {
            initMessaging();

            if (localStorage.getItem('disabledDesktopNotifications') !== 'false') {
                localStorage.setItem('disabledDesktopNotifications', 'false');
            }
        } else {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    initMessaging();

                    if (localStorage.getItem('disabledDesktopNotifications') !== 'false') {
                        localStorage.setItem('disabledDesktopNotifications', 'false');
                    }
                } else {
                    if (localStorage.getItem('disabledDesktopNotifications') !== 'true') {
                        localStorage.setItem('disabledDesktopNotifications', 'true');
                    }
                }
            });
        }
    }

    const initMessaging = () => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("./firebase-messaging-sw.js", {scope: '/my/'})
                .then((registration) => {
                    if(!firebase.apps.length) {
                        firebase.initializeApp({
                            apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
                            authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
                            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
                            projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
                            storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
                            messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
                            appId: process.env.REACT_APP_FIREBASE_APPID,
                        });
                    }
                    const messaging = firebase.messaging();

                    if (!firebase.messaging.isSupported()) {
                        return;
                    }

                    messaging.onTokenRefresh(() => {
                        messaging.getToken({
                            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                            serviceWorkerRegistration: registration,
                        }).then(
                            (refreshedToken: string) => {
                                if(localStorage.getItem('pushToken') !== refreshedToken) {
                                    sendPushTokenToServer(refreshedToken);
                                }
                            }).catch((err) => {
                                console.error(err);
                            });
                    });

                    messaging.getToken({
                        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                        serviceWorkerRegistration: registration,
                    }).then((token) => {
                        if(localStorage.getItem('pushToken') !== token) {
                            sendPushTokenToServer(token);
                        }
                    });

                    navigator.serviceWorker.addEventListener("message", (message) => {
                        addSingleNotification({
                            uid: Math.random().toString(36).substring(7),
                            title: message.data.notification.title,
                            message: message.data.notification.body,
                            read: false,
                            time: new Date(),
                            document: null,
                        });
                    });
                })
                .catch((err) => {
                    console.error("Service worker registration failed, error:", err);
                });
        }
    }

    const initPushApp = () => {
        PushNotifications.requestPermission().then(result => {
            if (result.granted) {
                PushNotifications.register();
            } else {
                console.error('registrationError - Hiba történt az értesítések feliratkozásakor.');
            }
        });

        PushNotifications.addListener('registration',
            (token: PushNotificationToken) => {
                if(localStorage.getItem('pushToken') !== token.value) {
                    sendPushTokenToServer(token.value);
                }
            }
        );

        PushNotifications.addListener('registrationError',
            (error: any) => {
                console.error('registrationError - Hiba történt az értesítések feliratkozásakor.');
            }
        );

        // Mikor az app meg van nyitva.
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotification) => {
                if(!authToken) {
                    return;
                }

                addSingleNotification({
                    uid: notification.id,
                    title: notification.title,
                    message: notification.body,
                    read: false,
                    time: new Date(),
                    document: null,
                });
            }
        );

        // Mikor rákattint aa felhasználó a notira ha az app a háttérben van.
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: PushNotificationActionPerformed) => {
                /*addNotification({
                    uid: notification.notification.data.id,
                    title: notification.notification.data.title,
                    message: notification.notification.data.body,
                    read: false,
                    time: new Date(),
                });*/

                //@TODO: Itt sajnos nem lehet kiolvasni a noti body-t és title-t szóval refreshelni kell a notikat.
                const data = notification.notification.data;
                if (data.document_id) {
                    App.openUrl({ 
                        url: `/my/documents/view/${data.document_id}`,
                    });
                } else {
                    App.openUrl({ 
                        url: '/my/notifications',
                    });
                }
            }
        );
    };

    const sendPushTokenToServer = (pushToken: string) => {
        Axios.post(`${apiUrl}/v1/push-token`, {
            token: pushToken,
        },
        {
            headers: { 'Authorization': `Bearer ${authToken}` },
        })
        .then(() => {
            localStorage.setItem('pushToken', pushToken);
        })
        .catch(err => {
            checkAxiosError(err);
        });
    }

    return (
        <>
            <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message="Hiba történt az értesítések feliratkozásakor."
                    duration={200}
                />

            {showPwaNotiAlert &&
                <IonAlert
                    isOpen={showPwaNotiAlert}
                    onDidDismiss={() => setShowPwaNotiAlert(false)}
                    header="Értesítések"
                    message="Szeretne feliratkozni a myPatent értesítéseire?"
                    buttons={[
                        {
                            text: 'Nem',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                if (localStorage.getItem('disabledDesktopNotifications') !== 'true') {
                                    localStorage.setItem('disabledDesktopNotifications', 'true');
                                }
                                setShowPwaNotiAlert(false)
                            }
                        },
                        {
                            text: 'Igen',
                            handler: () => {
                                setShowPwaNotiAlert(false);
                                initPushWeb();
                            }
                        }
                    ]}
                />
            }
        </>
    );
}

export default PushNotificationHelper;

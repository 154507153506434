import { isPlatform } from "@ionic/react";

export const GetBasicPlatforms = (): string => {
    if (isPlatform("desktop") || isPlatform("mobileweb")) {
        return "web";
    } else {
        if (isPlatform("android")) {
            return "android";
        } else if (isPlatform("ios")) {
            return "ios";
        }
    }
};

export default function ValidatePasswordRecoveryNew(values: any) {
    const errors:any = {};

    if(!values.password) {
        errors.password = 'A jelszó mező kötelező!';
    } else if(values.password.length < 6) {
        errors.password = 'A jelszó legalább 6 karakter!';
    }

    if(!values.passwordVerify) {
        errors.passwordVerify = 'A "Jelszó újra" mező kötelező!';
    } else if(values.passwordVerify.length < 6) {
        errors.passwordVerify = 'A "Jelszó újra" legalább 6 karakter!';
    }

    if(values.password !== values.passwordVerify) {
        errors.passwordVerify = 'A két jelszó nem egyezik meg!';
    }
    
    return errors;
}
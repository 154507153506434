import React from 'react';
import PageWrapper from '../layouts/PageWrapper';
import {
    IonButton, IonText, IonIcon,
} from "@ionic/react";
import { checkmarkCircleOutline } from 'ionicons/icons';

const InvoicesPaymentDonePage: React.FC = () => {
    return (
        <PageWrapper>

            <IonText color="success">
                <h1 className="ion-text-center alertIcon">
                    <IonIcon icon={checkmarkCircleOutline} />
                </h1>
                <h2 className="ion-text-center alertText">
                    Sikeres tranzakció!
                </h2>
            </IonText>

            <IonButton expand="block" color="success" routerLink="/my/dashboard" routerDirection="none">Vissza a főoldalra</IonButton>

        </PageWrapper>
    );
};

export default InvoicesPaymentDonePage;

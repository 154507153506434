import React, { useState, useEffect } from 'react';
import PageWrapper from '../layouts/PageWrapper';
import {
    IonButton,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
} from "@ionic/react";
import { RefresherEventDetail } from '@ionic/core';

import NotificationAccordionItem from '../../components/NotificationAccordionItem';

import { caretDown, downloadOutline, list } from 'ionicons/icons';
import { useNotification, NotificationType } from '../../context/Notifications';
import { useAuth } from '../../context/Auth';
import EmptyText from '../../components/EmptyText';
import Axios from 'axios';
import SkeletonAccordionItem from '../../components/SkeletonAccordionItem';
import FilterModal from '../../components/FilterModal';
import { Filter,  FilterJsonObject,  FilterObject, FilterQueryObject, getFiltersArray } from '../../helpers/FilterHelper';
import { useUrl } from '../../context/Url';

import './NotificationsPage.scss';

const NotificationsPage: React.FC = () => {
    const { authToken, checkAxiosError } = useAuth();
    const { apiUrl, fileUrl } = useUrl();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ disablePullToRefresh, setDisablePullToRefresh ] = useState<boolean>(true);
    const [ disableInfiniteScroll, setDisableInfiniteScroll ] = useState<boolean>(true);

    const [ filters, setFilters ] = useState([]);
    const [ skip, setSkip ] = useState<number>(0);

    const { notifications, addNotifications, clearNotifications, updateNotificationsCount } = useNotification();

    const pullToRefresh = (event?: CustomEvent<RefresherEventDetail>|null) => {
        updateNotificationsCount();
        getData(false, true).then(() => {
            if(event !== null) {
                event.detail.complete();
            }
        });
    }

    const infiniteScrollNext = (event: CustomEvent<void>) => {
        getData(true).then(() => {
            (event.target as HTMLIonInfiniteScrollElement).complete();
        });
    }

    const getData = async (next: boolean = false, forceRefresh: boolean = false) => {
        var filterQuery: FilterJsonObject[] = [];

        if(filters.length > 0) {
            filters.forEach((_fil: any) => {
                filterQuery.push({
                    key: _fil.key,
                    value: _fil.value,
                } as FilterJsonObject);
            });
        }
        
        var queryObject: FilterQueryObject = {
            skip: (next ? skip : 0),
            filters: filterQuery,
        };

        setDisablePullToRefresh(true);
        
        if(forceRefresh) {
            clearNotifications();
            setIsLoading(true);
            setSkip(0);
        }

        await Axios.post(`${apiUrl}/v1/messages/view`,
        queryObject,
        {
            headers: { 'Authorization': `Bearer ${authToken}` },
        }).then(res => {
            var response = res.data.data;

            if(response && response.length > 0) {
                if(response.length < 15) {
                    // Ha kevesebb mint 15 talált van akkor nem kell az infinite scroll mert úgy sem fog többet találni.
                    setDisableInfiniteScroll(true);
                } else {
                    setDisableInfiniteScroll(false);
                    setSkip(prevState => prevState + 15);
                }

                var messages: NotificationType[] = [];
                response.forEach((message: any) => {
                    var notif: NotificationType = {
                        uid: message.id,
                        title: message.title,
                        message: message.message,
                        time: new Date(message.created_at),
                        read: Boolean(message.opened_at !== null),
                        document: message.document,
                    };
                    messages.push(notif);
                });

                addNotifications(messages, false);
            } else {
                setDisableInfiniteScroll(true);
            }

            setIsLoading(false);
            setDisablePullToRefresh(false);
        }).catch(err => {
            checkAxiosError(err);
        });
    }

    /* Filters */
    const setFilterDefault = () => {}

    const setFilterValue = (name: string, value: string) => {
        const nextState = filters.map((item: FilterObject) => item.key === name ? { ...item, value: value } : item);
        setFilters(nextState);
    }

    const getFilters = () => {
        if(filters.length === 0) {
            Axios.get(`${apiUrl}/v1/messages/filters`,
            {
                headers: { 'Authorization': `Bearer ${authToken}` },
            }).then(res => {
                if (res.data.data && res.data.data.length > 0) {
                    var _filters = getFiltersArray(res.data.data);
                    setFilters(_filters);
                }
            }).catch(err => {
                checkAxiosError(err);
            });
        }
    }

    useEffect(() => {
        // Első betöltésnél kérje le a filtereket
        getFilters();
    }, []);

    useEffect(() => {
        // Ha változtatnak a szűrésen akkor firssítsük le a listát teljesen
        if(filters.length > 0) {
            getData(false, true);
        }
    }, [filters]);

    const pushWebInit = () => {
        if (localStorage.getItem('disabledDesktopNotifications') !== 'false') {
            localStorage.setItem('disabledDesktopNotifications', 'false');
        }

        window.location.reload();
    }

    return (
        <PageWrapper>
            <IonRefresher disabled={disablePullToRefresh} slot="fixed" onIonRefresh={pullToRefresh}>
                <IonRefresherContent
                    pullingIcon={caretDown}
                    pullingText=""
                    refreshingSpinner="crescent"
                    refreshingText="">
                </IonRefresherContent>
            </IonRefresher>

            <div className="hasButton">
                <h1 className="pageTitle">Értesítések</h1>
                <IonButton size="small" className="hideOnMobile" color="success" disabled={disablePullToRefresh} onClick={() => pullToRefresh(null)}>Frissítés</IonButton>
            </div>

            {localStorage.getItem('disabledDesktopNotifications') === 'true' &&
                <div id="desktopWelcome" className="notifications">
                    <h2>Push Értesítések</h2>
                    <p>Szeretne értesítést kapni számláiról, dokumentumairól? Engedélyezze a myPatent számára a push értesítéseket!</p>
                    <div className="inlineButtons">
                        <IonButton color="light" size="small" onClick={() => pushWebInit()}>
                            Értesítések bekapcsolása
                        </IonButton>
                    </div>
                </div>
            }

            {(filters.length > 0) &&
                <>
                    <FilterModal number={notifications.length ?? 0} setFilterDefault={setFilterDefault}>
                        {filters.map((filter, key) => (
                            <Filter key={key} filter={filter} setFilterValue={(name, value) => setFilterValue(name, value)} />
                        ))}
                    </FilterModal>
                    <br />
                </>
            }
                {(notifications.length > 0) ?
                (
                    <>
                        {notifications
                            .map(notification => (
                                <NotificationAccordionItem key={notification.uid} uid={notification.uid} title={notification.title} time={notification.time} unread={!notification.read}>
                                    <p>
                                        {notification.message}
                                    </p>

                                    {(notification.document !== null) &&
                                        <>
                                            <br/>
                                            <div className="rightButton">
                                                {notification.document.related_type === 'App\\Models\\DocumentInvoice' 
                                                ?
                                                    <IonButton size="small" routerLink={"/my/invoices/payment/" + notification.document.id} routerDirection="forward" color="primary">
                                                        <IonIcon icon={list} slot="start" />
                                                        Részletek
                                                    </IonButton>
                                                :
                                                    <IonButton size="small" routerLink={"/my/documents/view/" + notification.document.id} routerDirection="forward" color="primary">
                                                        <IonIcon icon={list} slot="start" />
                                                        Részletek
                                                    </IonButton>
                                                }
                                                {notification.document.file_id &&
                                                    <IonButton size="small" href={fileUrl + "/" + notification.document.file_id} target="_blank" color="success">
                                                        <IonIcon icon={downloadOutline} slot="start" />
                                                        PDF megnyitása
                                                    </IonButton>
                                                }
                                            </div>
                                        </>
                                    }
                                </NotificationAccordionItem>
                            ))
                        }
                    </>
                )
                :
                (isLoading ?
                    <SkeletonAccordionItem count={8} />
                    :
                    <EmptyText text="Nincs értesítés" subtext="Az Ön értesítései ezen a felületen fognak megjelenni." />
                )
            }

            {(!isLoading && disableInfiniteScroll && notifications.length > 0) &&
                <EmptyText text="Nincs több találat..." subtext="" />
            }

            <IonInfiniteScroll threshold="100px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e: CustomEvent<void>) => infiniteScrollNext(e)}>
                <IonInfiniteScrollContent
                    loadingSpinner="crescent"
                    loadingText="További elemek betöltése">
                </IonInfiniteScrollContent>
            </IonInfiniteScroll>

        </PageWrapper>
    );
};

export default NotificationsPage;

import React, { useState } from 'react';
import {
    IonContent,
    IonGrid,
    IonButton,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonLoading,
    IonAlert,
} from "@ionic/react";
import { Redirect } from 'react-router-dom';
import { useAuth } from '../context/Auth';
import Axios from 'axios';

import MyPatentHeader from '../components/MyPatentHeader';

import logo from '../assets/images/logo.svg';
import './LoginPage.scss';
import useFormValidation from '../helpers/FormValidationHelper';
import ValidateRegPin from '../helpers/validators/ValidateRegPin';
import { setLoading, setInitError, hasError, addApiErrors, ErrorsList } from '../helpers/GlobalHelpers';
import { useUrl } from '../context/Url';

const INIT_STATE = {
    pin: '',
};

const RegistrationVerifyPage: React.FC = () => {
    const { handleChange, values } = useFormValidation(INIT_STATE);
    const { loggedIn, userInfo, setUserInfo, setLogout, authToken } = useAuth();
    const { apiUrl } = useUrl();
    const [ isLogoHidden, setIsLogoHidden ] = useState<boolean>(false);
    const [ showRedirect, setShowRedirect ] = useState<boolean>(false);
    const [ newPinSent, setNewPinSent ] = useState<boolean>(false);
    const [ status, setStatus ] = useState({
        loading: false,
        error_list: INIT_STATE,
    });

    const getScrollTop = (e: CustomEvent) => {
        if(e.detail.scrollTop >= 60) {
            setIsLogoHidden(true);
        } else {
            setIsLogoHidden(false);
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === 'Enter') {
            handleSendPin();
        }
    }

    const handleSendPin = async () => {
        setLoading(true, setStatus);
        setInitError(INIT_STATE, setStatus);

        const errors = ValidateRegPin(values);
        if (hasError(errors)) {
            setLoading(false, setStatus);
            addApiErrors(errors, setStatus);
        } else {
            Axios.post(`${apiUrl}/v1/register-confirm`, {
                pin: values.pin,
            },
            {
                headers: {'Authorization': `Bearer ${authToken}`},
            }).then(res => {
                setLoading(false, setStatus);
                var newUserInfo = userInfo;
                newUserInfo.email_verified_at = res.data.date;
                setUserInfo(newUserInfo);
                setShowRedirect(true);
            }).catch(err => {
                setLoading(false, setStatus);
                if(!err.response) {
                    addApiErrors('Hálózati hiba.', setStatus);
                } else {
                    addApiErrors(err.response.data.message, setStatus);
                }
            });
        }
    }

    const handleNewPin = async () => {
        setLoading(true, setStatus);

        Axios.get(`${apiUrl}/v1/new-pin`,
        {
            headers: {'Authorization': `Bearer ${authToken}`},
        }).then(res => {
            setLoading(false, setStatus);
            setNewPinSent(true);
        }).catch(err => {
            setLoading(false, setStatus);
            if(!err.response) {
                addApiErrors('Hálózati hiba.', setStatus);
            } else {
                addApiErrors(err.response.data.message, setStatus);
            }
        });
    }

    if((loggedIn !== null && loggedIn === false) || showRedirect) {
        return <Redirect to="/" />;
    }

    if (loggedIn !== null && loggedIn === true && userInfo && userInfo.email_verified_at != null) {
        return <Redirect to="/my/dashboard" />;
    }

    return (
        <IonPage>
            <IonContent id="loginPage">
                <IonContent className="backgroundTransparent" scrollEvents={true} onIonScroll={(e) => getScrollTop(e)}>
                    <MyPatentHeader isLogoHidden={isLogoHidden} />

                    <IonGrid className="ion-padding" id="ionGridInner" fixed>
                        <div className="page-content">
                            <img src={logo} alt="myPatent Logo" className="desktopLogo" />

                            <h1 className="pageTitle">Regisztráció</h1>

                            <h2>Köszönjük érdeklődését!</h2>
                            <p>A megadott e-mail címre ({userInfo ? userInfo.email : ' - '}) egy megerősítő levelet küldtünk. Kérjük adja meg az abban található Pin kódot.</p>
                        
                            <div className="form">
                                <IonItem lines="none" className={"rounded-input " + (Boolean(status.error_list) && status.error_list.pin ? 'error' : '')}>
                                    <IonLabel position="floating">Pin kód</IonLabel>
                                    <IonInput onKeyDown={handleKeyDown} clearOnEdit={false} type="text" name="pin" onIonChange={handleChange} value={values.pin} required autofocus={true}></IonInput>
                                </IonItem>

                                <ErrorsList errors={status.error_list} />
                                
                                <div className="ion-text-center">
                                    <IonButton expand="block" onClick={handleSendPin}>Beküldés</IonButton>
                                </div>
                            </div>

                            <IonButton onClick={() => setLogout()} color="medium">Kijelentkezés</IonButton>

                            <br/>

                            <p>Nem kapta meg a levelet?</p>
                            <IonButton onClick={() => handleNewPin()} color="medium">Új Pin kód kérése</IonButton>

                            <IonLoading
                                isOpen={status.loading || userInfo === null}
                                message={'Kérem várjon...'}
                            />
                        </div>
                    </IonGrid>
                </IonContent>
            </IonContent>

            <IonAlert
                isOpen={newPinSent}
                onDidDismiss={() => setNewPinSent(false)}
                header={'Siker'}
                message={'Az új Pin kódot kiküldtük!'}
                buttons={[
                    {
                        text: 'Rendben',
                        role: 'cancel',
                    }
                ]}
                /> 
        </IonPage>
    );
}

export default RegistrationVerifyPage;

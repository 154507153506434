import React, { useState } from 'react';
import {
    IonItem,
    IonIcon,
    IonLabel,
    IonText,
    IonChip,
} from "@ionic/react";

import { chevronDownOutline, checkmarkCircle, hourglass, warning } from 'ionicons/icons';

import './AccordionItem.scss';
import NumberFormat from 'react-number-format';

type Props = {
    children?: any;
    type?: string;
    createdAt?: string;
    price?: number;
    docDueDate?: string;
    related: any;
    transactions: any;
}

const InvoiceAccordionItem: React.FC<Props> = props => {
    const { children, price, docDueDate, related, transactions } = props;
    const [ isOpen, setIsOpen ] = useState(false);
    var dateFormat = require('dateformat');

    const handleOpen = () => { 
        setIsOpen(!isOpen);
    }

    const getIcon = () => {
        switch (getStatus()) {
            case 'late':
                return warning;
            case 'waiting':
                return hourglass;
            default:
                return checkmarkCircle;
        }
    }

    const getColor = () => {
        switch (getStatus()) {
            case 'waiting':
                return 'warning';
            case 'paid':
                return 'success';
            case 'late':
                return 'danger';
            default:
                return 'primary';
        }
    }

    const getStatus = () => {
        if(related.U_CPH_PaymentCreated !== undefined && related.U_CPH_PaymentCreated !== null && related.U_CPH_PaymentCreated === 'Y') {
            return 'paid';
        }

        if(transactions !== undefined && transactions.length > 0 && transactions[0] !== undefined && transactions[0].status === 'FINISHED') {
            return 'paid';
        } else {
            var d1 = new Date();
            var d2 = new Date(related.DocDueDate);
            if (d1 > d2) {
                return 'late';
            } else {
                return 'waiting';
            }
        }
    }

    return (
        <div className="accordionItemWrapper">
            <IonItem className={"accordionItem " + (isOpen ? "isOpen" : "")} lines="none" button={true} detail={false} onClick={() => handleOpen()}>
                <IonIcon icon={getIcon()} slot="start" color={getColor()} />
                <IonLabel>
                    <IonText color="secondary">
                        <h2 className="title">
                            Számla - <NumberFormat value={price} displayType={'text'} thousandSeparator={" "} suffix={' Ft'} />
                        </h2>
                    </IonText>
                    {docDueDate &&
                        <IonChip color={getColor()}>
                            <IonLabel>Fizetési határidő: {docDueDate ? dateFormat(docDueDate, 'yyyy.mm.dd') : ''}</IonLabel>
                        </IonChip>
                    }
                </IonLabel>
                <IonIcon icon={chevronDownOutline} slot="end" size="small" className="chevronIcon" />
            </IonItem>
            <div className={"subItem " + (isOpen ? "isOpen" : "")}>
                <div className="subItem-inner">
                    {children??''}
                </div>
            </div>
        </div>
    );
};

export default InvoiceAccordionItem;

import React from 'react';

type Props = {
    text: string;
    subtext?: string;
}

const EmptyText: React.FC<Props> = props => {
    const { text, subtext } = props;
    return (
        <div className="emptyText">
            <h6>
                {text}
            </h6>
            <p>
                {subtext}
            </p>
        </div>
    );
};

export default EmptyText;